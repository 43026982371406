import {
	LayoutContent,
	LayoutFooter,
} from "@/scripts/contexts/admin/components/Layout"
import { PageTitle } from "@/scripts/components/common-ui/PageTitle"

export const AdminIndexPage = () => (
	<>
		<PageTitle title={"ダッシュボード"} />
		<LayoutContent>content for admin</LayoutContent>
		<LayoutFooter>footer</LayoutFooter>
	</>
)
