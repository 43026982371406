import { gqlClient } from "@/scripts/contexts/networking/graphql-client"
import { SequentialTaskQueue } from "@/scripts/contexts/upload/SequentialTaskQueue"
import { gql } from "@/scripts/generated"
import axios from "axios"
import { eventmit, type Eventmitter } from "eventmit"
import invariant from "tiny-invariant"

export const uploadFile = (file: File) => {
	return new MultipartUploaderProgress(file)
}

export type UploaderEvent =
	| UploaderEventInit
	| UploaderEventSetup
	| UploaderEventProgress
	| UploaderEventComplete
	| UploaderEventError

export type UploaderEventInit = {
	type: "init"
}

export type UploaderEventSetup = {
	type: "setup"
}

export type UploaderEventProgress = {
	type: "progress"
	progress: number
}

export type UploaderEventComplete = {
	type: "complete"
	result: { storage_path: string; preview_url: string }
}

export type UploaderEventError = {
	type: "error"
	error: any
}

export type UploaderEventWithMetadata = UploaderEvent & { meta: { file: File } }

export type UploaderEventHandler = (event: UploaderEventWithMetadata) => void

export class MultipartUploaderProgress {
	public readonly file: File
	private emitter: Eventmitter<UploaderEventWithMetadata>
	public status: UploaderEvent

	constructor(file: File) {
		this.file = file
		this.status = { type: "init" }
		this.emitter = eventmit()
	}

	public on(cb: UploaderEventHandler) {
		this.emitter.on(cb)
	}

	public async start(abort: AbortController | null) {
		// 5MB
		const chunkSize = 5 * 1024 * 1024

		// Setup

		const req = gqlClient.mutation(
			CreateMultipartUploadDoc,
			{
				filename: this.file.name,
				mimetype: this.file.type,
				parts: Math.ceil(this.file.size / chunkSize),
			},
			{
				fetchOptions: {
					signal: abort?.signal,
				},
			}
		)
		this.notifyStatus({ type: "setup" })
		const result = await req.toPromise().catch((err) => {
			this.notifyStatus({ type: "error", error: err })
		})
		if (!result) {
			this.notifyStatus({
				type: "error",
				error: new Error("Failed to create multipart upload"),
			})
			return
		}
		if (result.error) {
			this.notifyStatus({ type: "error", error: result.error })
			return
		}

		const token = result.data?.createMultipartUploadToken?.token
		const parts = result.data?.createMultipartUploadToken?.parts
		if (!token || !parts || parts.length === 0) {
			this.notifyStatus({ type: "error", error: new Error("Invalid response") })
			return
		}

		// Upload chunk
		this.notifyStatus({ type: "progress", progress: 0 })
		const worker = new SequentialTaskQueue(
			parts.map((part, idx) => async (onProgress) => {
				const blob = this.file.slice(idx * chunkSize, (idx + 1) * chunkSize)
				const size = blob.size
				const resp = await axios.put(part, blob, {
					signal: abort?.signal,
					onUploadProgress: (e) => {
						onProgress({ loaded: e.loaded, total: e.total ?? size })
					},
				})
				const etag = resp.headers["etag"]
				invariant(etag, 'Missing "etag" header')
				return {
					partNumber: idx + 1,
					etag,
				}
			})
		)

		worker.onProgress((event) => {
			this.notifyStatus({ type: "progress", progress: event.allProgress })
		})
		const partsResult = await worker.dequeueAll().catch((err) => {
			this.notifyStatus({ type: "error", error: err })
		})
		if (!partsResult) return

		// Finalize
		const finalizeResult = await gqlClient
			.mutation(FinalizeMultipartUploadDoc, {
				token,
				parts: partsResult,
			})
			.toPromise()
			.catch((err) => {
				this.notifyStatus({ type: "error", error: err })
			})
		if (!finalizeResult?.data?.finalizeMultipartUpload) return
		if (finalizeResult.error) {
			this.notifyStatus({ type: "error", error: finalizeResult.error })
			return
		}

		this.notifyStatus({
			type: "complete",
			result: finalizeResult.data.finalizeMultipartUpload,
		})
	}

	private notifyStatus(event: UploaderEvent) {
		this.status = event
		this.emitter.emit({
			...event,
			meta: {
				file: this.file,
			},
		})
	}
}

const CreateMultipartUploadDoc = gql(/* GraphQL */ `
  mutation createMultipartUpload($filename: String!, $mimetype: String!, $parts: Int!) {
    createMultipartUploadToken(input: { filename: $filename, mimetype: $mimetype, parts: $parts }) {
      token
      parts
    }
  }
`)

const FinalizeMultipartUploadDoc = gql(/* GraphQL */ `
  mutation finalizeMultipartUpload($token: String!, $parts: [FinalizeMultipartUploadInputPart]!) {
    finalizeMultipartUpload(input: { token: $token, parts: $parts }) {
      storage_path
      preview_url
    }
  }
`)
