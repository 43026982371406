import type { SidebarNavProps } from "@/scripts/components/layout/SidebarNav"
import ImgIconImg from "@/images/icon/icon--img.svg?react"
import React from "react"
import ImgBox from "@/images/icon/icon--folder.svg?react"

export const StoreYbPageSidebarNav: SidebarNavProps["sections"] = [
	{
		t: "section",
		key: "audit",
		label: "販促物審査",
		labelIcon: <ImgIconImg />,
		items: [
			{
				t: "item",
				label: "承認待ちアイテム",
				key: "ongoing",
				match: "/yodobashi/material",
			},
		],
	},
]
export const StoreYbPageSidebarNavButtons: SidebarNavProps["itemsAfterSections"] =
	[
		{
			t: "item",
			key: "data-box",
			label: "データBOX",
			labelIcon: <ImgBox />,
			match: { to: "/yodobashi/databox" },
		},
	]
