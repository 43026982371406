import * as Sentry from "@sentry/core"
import { devtoolsExchange } from "@urql/devtools"
import { contextExchange } from "@urql/exchange-context"
import { type DocumentNode, Kind } from "graphql"
import { isTruthy } from "typesafe-utils"
import {
	type Exchange,
	type Operation,
	cacheExchange,
	createClient,
	errorExchange,
	fetchExchange,
} from "urql"

const exchanges: Exchange[] = [
	devtoolsExchange,
	// __DEV__ && debugExchange,
	cacheExchange,
	// refocusExchange(),
	contextExchange({
		getContext: async (operation) => {
			return {
				...operation.context,
				url: annotatedURL(operation),
			}
		},
	}),
	fetchExchange,
	errorExchange({
		onError(error, operation) {
			Sentry.withScope((scope) => {
				scope.setTag("kind", operation.kind)
				scope.setExtra("operationName", getOperationName(operation.query))
				scope.setExtra("variables", operation.variables)
				Sentry.captureException(error)
			})
		},
	}),
].filter(isTruthy)

// urql 4.x
// https://github.com/urql-graphql/urql/blob/af5b90b7bb9aec77638a44671e9cddff531c16e1/packages/core/src/utils/request.ts#L170C1-L176C3
const getOperationName = (query: DocumentNode): string | undefined => {
	for (const node of query.definitions) {
		if (node.kind === Kind.OPERATION_DEFINITION) {
			return node.name ? node.name.value : undefined
		}
	}
}

/** リクエストのURLにoperationNameを付与する */
const annotatedURL = (operation: Operation) => {
	// contextは使い回されるため、パラメーターが既に存在する場合を考慮してURLクラスを使用してます
	// 相対パスを処理する良い方法がわからなかったためダミーのbase URLを指定しています。
	const url = new URL(operation.context.url, "http://example.com")
	url.searchParams.set("op", getOperationName(operation.query) ?? "")
	return url.host === "example.com"
		? `${url.pathname}${url.search}`
		: url.toString()
}

export const gqlClient = createClient({
	url: "/graphql",
	exchanges,
	suspense: true,
})
