import { FileInputWithPreview } from "@/scripts/contexts/databox/components/handleSelect"
import { useFormSelectFile } from "@/scripts/contexts/databox/components/useFormSelectFile"
import { validateFolderFile } from "@/scripts/contexts/databox/folderValidation"
import { type FragmentType, gql, useFragment } from "@/scripts/generated"
import type { EditFolderMutationVariables } from "@/scripts/generated/graphql"
import {
	Button,
	Checkbox,
	Grid,
	Group,
	Stack,
	TextInput,
	Title,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useModals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { type FormEvent, useCallback, useMemo, useRef } from "react"
import { useMutation, useQuery } from "urql"
import { FOLDER_THUMBNAIL_MIME_TYPE } from "../../_common/lib/fileTypes"

export const useFolderEditModal = () => {
	const modal = useModals()
	return useCallback(
		(props: Pick<FolderEditModalContentProps, "params">) => {
			const modalID = modal.openModal({
				title: <Title order={3}>フォルダを編集</Title>,
				size: "xl",
				children: (
					<FolderEditModalContentWrapper
						onClose={() => modal.closeModal(modalID)}
						params={props.params}
					/>
				),
			})
		},
		[modal]
	)
}

type FolderEditModalContentProps = {
	onClose: () => unknown
	params: Omit<
		EditFolderMutationVariables,
		"thumbnail" | "name" | "remove_thumbnail"
	>
}

const FolderEditModalContentWrapper = (props: FolderEditModalContentProps) => {
	const [{ data }] = useQuery({
		query: EditFolderQuery,
		variables: {
			id: props.params.id,
		},
		context: useMemo(() => ({ suspense: false }), []),
	})

	const databox =
		data?.node && data?.node.__typename === "Databox" ? data.node : null
	return databox ? (
		<FolderEditModalContent
			onClose={props.onClose}
			params={props.params}
			databox={databox}
		/>
	) : null
}

const FolderEditModalContent = (
	props: FolderEditModalContentProps & {
		databox: FragmentType<typeof EditFolderModalFragment>
	}
) => {
	const databox = useFragment(EditFolderModalFragment, props.databox)
	const form = useForm({
		initialValues: {
			name: databox.name,
			remove_thumbnail: false,
			thumbnail: "",
		},
		validate: {
			name: (value) => {
				return value.length > 255 ? "255文字以内で入力してください" : null
			},
		},
	})

	const handleChangeFile = useFormSelectFile(
		form,
		"thumbnail",
		validateFolderFile
	)

	const [editFolderResult, editFolder] = useMutation(EditFolderMutation)
	const handleSubmit = async (values: typeof form.values, event: FormEvent) => {
		event.preventDefault()

		const result = await editFolder(
			{
				...props.params,
				name: values.name,
				remove_thumbnail: values.remove_thumbnail,
				thumbnail: !values.remove_thumbnail
					? fileInputRef.current?.files?.[0]
					: null,
			},
			{
				additionalTypenames: ["Databox", "DataboxView"],
				suspense: false,
			}
		)

		if (!result.error) {
			props.onClose()
			showNotification({
				title: "処理完了",
				message: "フォルダを編集しました",
			})
			return
		}
	}

	const fileInputRef = useRef<HTMLInputElement>(null)

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Stack>
				<Grid grow>
					<Grid.Col span={2}>
						<Stack>
							<FileInputWithPreview
								ref={fileInputRef}
								name={"thumbnail"}
								onChangeFile={handleChangeFile}
								errors={form.errors["thumbnail"]}
								accept={FOLDER_THUMBNAIL_MIME_TYPE.join(",")}
								wrapperProps={{
									sx: {
										opacity: form.values["remove_thumbnail"] ? "0.3" : "1",
										pointerEvents: form.values["remove_thumbnail"]
											? "none"
											: "auto",
									},
								}}
							/>
							<Checkbox
								size={"sm"}
								label={"カバー画像削除"}
								{...form.getInputProps("remove_thumbnail")}
							/>
						</Stack>
					</Grid.Col>

					<Grid.Col span={8}>
						<Stack>
							<TextInput
								size={"md"}
								label={"フォルダ名"}
								placeholder={"変更後のフォルダ名"}
								data-autofocus
								maxLength={255}
								required
								{...form.getInputProps("name")}
							/>
						</Stack>
					</Grid.Col>
				</Grid>

				{
					// TODO: エラーメッセージ取得
					editFolderResult.error && <div>フォルダを編集できませんでした。</div>
				}

				<Group mx={"auto"}>
					<Button type="button" variant="white" onClick={props.onClose}>
						キャンセル
					</Button>
					<Button type="submit" disabled={editFolderResult.fetching}>
						保存
					</Button>
				</Group>
			</Stack>
		</form>
	)
}

const EditFolderQuery = gql(/* GraphQL */ `
  query EditFolderModal($id: ID!) {
    node(id: $id) {
      ... on Databox {
        ...EditFolderModal
      }
    }
  }
`)

const EditFolderModalFragment = gql(/* GraphQL */ `
  fragment EditFolderModal on Databox {
    __typename
    id
    name
    attachmentUrl
    thumbnailImageUrl
  }
`)

const EditFolderMutation = gql(/* GraphQL */ `
  mutation EditFolder($id: ID!, $name: String, $thumbnail: Upload, $remove_thumbnail: Boolean) {
    databoxFolderUpdate(id: $id, name: $name, thumbnail: $thumbnail, removeThumbnail: $remove_thumbnail) {
      __typename
      id

      parentView {
        __typename
        id
      }
    }
  }
`)
