import { type RefObject, useEffect, useRef } from "react"

export const useAbortController = () => {
	const ref = useRef<AbortController>()
	useEffect(() => {
		ref.current = new AbortController()
		return () => {
			ref.current?.abort()
		}
	}, [])
	return ref as RefObject<AbortController>
}
