import { TextInput, type TextInputProps } from "@mantine/core"
import { type UseFormReturnType, useForm } from "@mantine/form"
import ImgSearch from "@/images/icon/icon--search.svg?react"

type TForm = UseFormReturnType<{ search: string }>

export type SearchFormProps = {
	defaultValue?: string
	onSubmit: Parameters<TForm["onSubmit"]>[0]
	key?: string
} & Pick<TextInputProps, "placeholder">

/** 検索フォーム */
export const SearchForm = (props: SearchFormProps) => {
	const form: TForm = useForm({
		initialValues: {
			search: props.defaultValue ?? "",
		},
	})

	return (
		<form
			onSubmit={form.onSubmit(props.onSubmit)}
			className={"inputWrap inputWrap--search"}
		>
			<TextInput
				type="search"
				name="search"
				placeholder={props.placeholder}
				{...form.getInputProps("search")}
			/>
			<button className={"inputWrap--search__button"} type={"submit"}>
				<ImgSearch />
			</button>
		</form>
	)
}
