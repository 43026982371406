import { formatDate } from "@/scripts/components/strings/FormatDate"
import { useCurrentUser } from "@/scripts/contexts/auth/context"
import {
	availableOperationByFile,
	FileOperationMode,
	useDataboxFileOperationModal,
} from "@/scripts/contexts/databox/components/useDataboxFileOperationModal"
import { useDataboxPreviewModal } from "@/scripts/contexts/databox/components/useDataboxPreviewModal"
import { useFolderEditModal } from "@/scripts/contexts/databox/components/useFolderEditModal"
import { gql, useFragment } from "@/scripts/generated"
import type { UserType } from "@/scripts/generated/graphql"
import { isNode } from "@/scripts/lib/graphql-consumer"
import type { ResultOf } from "@graphql-typed-document-node/core"
import { Text, UnstyledButton } from "@mantine/core"
import { useMemo } from "react"
import { useQuery } from "urql"
import { encodeURL } from "js-base64"

//const viewableFileTypes = new Set(["jpg", "jpeg", "png", "gif", "pdf"])

const NoContent = () => (
	<div className="adminDataBox__panelSidebarContent">
		<div className="adminDataBox__panelSidebarIcon">
			<i className="icon icon--folderLarge"></i>
		</div>
		<p className="adminDataBox__panelSidebarNoneSelectMessage">
			選択されているフォルダはありません
		</p>
	</div>
)

const MultipleContent = (props: {
	files: Pick<DataboxFileInfoItem, "dbId">[]
}) => (
	<div className="adminDataBox__panelSidebarContent">
		<div className="adminDataBox__panelSidebarIcon">
			<i className="icon icon--folderLarge"></i>
		</div>
		<p className="adminDataBox__panelSidebarNoneSelectMessage">複数選択中</p>
		<Text align="center" className="dataBoxListLink__infoMainLinkListItem">
			<a
				href={`/storage/m/${encodeURL(props.files.map((f) => f.dbId).join(","))}`}
			>
				ダウンロード
			</a>
		</Text>
	</div>
)

/** 選択中のファイルの詳細(右横に表示する縦長の画面)
 *
 * - 1つのみ選択されている場合は情報を表示する
 * - 複数選択されている場合は: "複数選択中" と表示する
 * - 1つも選択されていない場合は: "選択されているフォルダはありません" と表示する
 **/
export const DataboxFileInfo = ({
	files,
}: {
	// Client, Databoxの両方に対応できるゆる目のprops
	files: DataboxFileInfoItem[]
}) => {
	const file = files.length === 1 ? files[0] : null
	const [_, userType] = useCurrentUser()
	const fileIsDatabox = file?.__typename === "Databox"
	const [{ data: fileDetail }] = useQuery({
		query: DataboxFileInfoDoc,
		variables: { id: file?.id ?? "" },
		pause: !fileIsDatabox,
		context: useMemo(() => ({ suspense: false }), []),
	})

	const openPreview = useDataboxPreviewModal()
	const openOperationConfirm = useDataboxFileOperationModal()
	const openFolderEdit = useFolderEditModal()

	if (!file && files.length > 0) {
		return (
			<MultipleContent
				files={files.filter((f) => f.__typename === "Databox")}
			/>
		)
	} else if (!file) {
		return <NoContent />
	}

	// TODO: DataboxFileInfoDataboxFragmentが複数のフラグメントをspreadしているが展開されない
	const databox =
		fileDetail?.node && fileDetail?.node?.__typename === "Databox"
			? useFragment(DataboxFileInfoDataboxFragment, fileDetail.node)
			: null
	const databoxView = fileDetail
		? useFragment(DataboxFileInfoDataboxViewFragment, fileDetail.databoxView)
		: null
	const mutationReady = !!(
		databoxView &&
		databox &&
		databoxView.id === file?.dbId.toString()
	)

	const {
		showCounts,
		fileDeletable,
		fileSoftDeleteAsDeletable,
		fileSoftDeletable,
		fileRestorable,
		fileDownloadable,
		fileViewable,
		folderDownloadable,
		folderEditable,
		folderDeletable,
		folderSoftDeleteAsDeletable,
		folderRestorable,
		folderSoftDeletable,
	} = availableOperationByFile(databox, userType as UserType)

	const extension = isNode(file, "Databox") ? file.fileType : "folder"
	const iconStyle =
		"thumbnailImageUrl" in file && file.thumbnailImageUrl
			? { backgroundImage: `url("${file.thumbnailImageUrl}")` }
			: undefined

	return (
		<div className="adminDataBox__panelSidebarContent">
			<div className="dataBoxListLink__icon">
				<i
					className="icon icon--folderLarge"
					data-extension={extension}
					style={iconStyle}
				/>
			</div>
			<p className="dataBoxListLink__caption">{file.name}</p>
			<dl className="dataBoxListLink__info">
				<dt className="dataBoxListLink__infoCaption">作成日</dt>
				<dd className="dataBoxListLink__infoMain">
					{formatDate(file.created_at)}
				</dd>
				<dt className="dataBoxListLink__infoCaption">更新日</dt>
				<dd className="dataBoxListLink__infoMain">
					{formatDate(file.updated_at)}
				</dd>

				{
					// NOTE: ファイルを切り替えたときに、データをクリアできないので、ここで判定する
					showCounts && mutationReady && (
						<>
							<dt className="dataBoxListLink__infoCaption">
								フォルダー / ファイル 数
							</dt>
							<dd className="dataBoxListLink__infoMain">
								{databoxView.num_folders}/{databoxView.num_files}
							</dd>
						</>
					)
				}
			</dl>

			{mutationReady && (
				<ul className="dataBoxListLink__infoMainLinkList">
					{folderEditable && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton
								onClick={() =>
									openFolderEdit({
										params: {
											id: databox.id,
										},
									})
								}
							>
								<span>フォルダ編集</span>
							</UnstyledButton>
						</li>
					)}
					{folderDownloadable && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<a
								href={folderDownloadable.url}
								download={folderDownloadable.name}
							>
								ダウンロード
							</a>
						</li>
					)}
					{fileViewable && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton onClick={() => openPreview(databox)}>
								拡大表示
							</UnstyledButton>
						</li>
					)}
					{fileDownloadable && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<a
								href={fileDownloadable.url}
								target={"_blank"}
								download={fileDownloadable.name}
								rel="noreferrer"
							>
								ダウンロード
							</a>
						</li>
					)}
					{(fileDeletable || folderDeletable) && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton
								onClick={() =>
									openOperationConfirm(databox, FileOperationMode.DELETE)
								}
								className="dataBoxListLink__infoMainLinkListItemDeleteLink2"
							>
								完全に削除
							</UnstyledButton>
						</li>
					)}
					{(fileSoftDeleteAsDeletable || folderSoftDeleteAsDeletable) && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton
								onClick={() =>
									openOperationConfirm(
										databox,
										FileOperationMode.SOFT_AS_DELETE
									)
								}
								className="dataBoxListLink__infoMainLinkListItemDeleteLink2"
							>
								{fileSoftDeleteAsDeletable ? "ファイル" : "フォルダ"}を削除
							</UnstyledButton>
						</li>
					)}
					{(fileSoftDeletable || folderSoftDeletable) && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton
								onClick={() =>
									openOperationConfirm(databox, FileOperationMode.SOFT_DELETE)
								}
								className="dataBoxListLink__infoMainLinkListItemSoftDeleteLink"
							>
								削除済みにする
							</UnstyledButton>
						</li>
					)}
					{(fileRestorable || folderRestorable) && (
						<li className="dataBoxListLink__infoMainLinkListItem">
							<UnstyledButton
								onClick={() =>
									openOperationConfirm(databox, FileOperationMode.RESTORE)
								}
							>
								復元する
							</UnstyledButton>
						</li>
					)}
				</ul>
			)}
		</div>
	)
}

export type DataboxFileInfoItem =
	| ResultOf<typeof DataboxClientFileInfoFragment>
	| ResultOf<typeof DataboxFileFileInfoFragment>

const DataboxFileInfoDoc = gql(/* GraphQL */ `
  query DataboxFileInfo($id: ID!) {
    databoxView(id: $id) {
      ...DataboxFileInfoDataboxView
    }

    node(id: $id) {
      ... on Databox {
        ...DataboxFileInfoDatabox
      }
    }
  }
`)

const DataboxFileInfoDataboxViewFragment = gql(/* GraphQL */ `
  fragment DataboxFileInfoDataboxView on DataboxView {
    num_files
    num_folders
    id
  }
`)

const DataboxFileInfoDataboxFragment = gql(/* GraphQL */ `
  fragment DataboxFileInfoDatabox on Databox {
    __typename
    id
    dbId
    attachmentUrl
    ...DataboxAvailableOperation
    ...DataboxPreviewModal
    ...DataboxDestroyModal
  }
`)

// Required props

const DataboxFileFileInfoFragment = gql(/* GraphQL */ `
  fragment DataboxFileFileInfo on Databox {
    id
    dbId
    name
    created_at
    updated_at
    thumbnailImageUrl
    fileType
    nodeType
    trashed
  }
`)

const DataboxClientFileInfoFragment = gql(/* GraphQL */ `
  fragment DataboxClientFileInfo on Client {
    id
    dbId
    name
    created_at
    updated_at
  }
`)
