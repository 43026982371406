import { PageTitle } from "@/scripts/components/common-ui/PageTitle"
import { LayoutContent, LayoutFooter } from "../components/Layout"

export const StoreYbIndexPage = () => (
	<>
		<PageTitle title="ダッシュボード" />
		<LayoutContent>content for yb</LayoutContent>
		<LayoutFooter>footer</LayoutFooter>
	</>
)
