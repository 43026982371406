import type { UseFormReturnType } from "@mantine/form"
import { useCallback } from "react"

export const useFormSelectFile = (
	form: UseFormReturnType<any>,
	fieldName: string,
	validator: (file: File | undefined) => { error: string } | undefined
) => {
	const handleChangeFile = useCallback(
		(file: File | undefined) => {
			form.setFieldValue(fieldName, file ? file.name : "")
			const err = validator(file)
			if (err) {
				form.setFieldError(fieldName, err.error)
			} else {
				form.clearFieldError(fieldName)
			}
		},
		[form]
	)
	return handleChangeFile
}
