import {
	UploadProgress,
	type UploadProgressProps,
	UploadProgressStylesNames,
} from "./UploadProgress"
import { useEffect, useState } from "react"
import { useFileObjectURL } from "@/scripts/contexts/react/hooks/useFileObjectURL"
import { createStyles } from "@mantine/core"

export type FileUploadProgressProps = {
	file: File
} & Omit<UploadProgressProps, "name" | "fileType" | "previewSrc">

/** ファイルのrefを渡すとプレビュー画像を */
export const FileUploadProgress = ({
	file,
	...props
}: FileUploadProgressProps) => {
	const preview = useFileObjectURL(file)

	return (
		<UploadProgress
			{...props}
			label={file.name}
			fileType={file.type}
			previewSrc={preview ?? undefined}
		/>
	)
}
