import { type FragmentType, gql, useFragment } from "@/scripts/generated"
import { DataboxNodeType, UserType } from "@/scripts/generated/graphql"
import type { ResultOf } from "@graphql-typed-document-node/core"
import {
	Alert,
	Box,
	Button,
	type DefaultMantineColor,
	Group,
	Mark,
	Stack,
} from "@mantine/core"
import { useModals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"
import { useMutation } from "urql"

export enum FileOperationMode {
	/** 論理削除 */
	SOFT_DELETE = "soft_delete",
	/** 論理削除だが削除として表示 */
	SOFT_AS_DELETE = "soft_as_delete",
	/** 物理削除 */
	DELETE = "hard_delete",
	/** 復元 */
	RESTORE = "restore",
}

const viewableFileTypes = new Set(["jpg", "jpeg", "png", "gif", "pdf"])

type FileOperations = {
	fileDeletable: boolean
	fileSoftDeletable: boolean
	fileSoftDeleteAsDeletable: boolean
	fileRestorable: boolean
	fileDownloadable: { url: string; name: string } | undefined | null
	fileViewable: { url: string } | undefined | null
}

type FolderOperations = {
	folderEditable: boolean
	folderDownloadable: { url: string; name: string } | undefined | null
	showCounts: boolean
	// NOTE: この辺りはファイルと共通化しても問題ない
	folderDeletable: boolean
	folderSoftDeletable: boolean
	folderSoftDeleteAsDeletable: boolean
	folderRestorable: boolean
}

const DataboxAvailableOperationFragment = gql(/* GraphQL */ `
  fragment DataboxAvailableOperation on Databox {
    id
    name
    trashed
    nodeType
    attachmentUrl
    fileType
    editable
  }
`)

export const availableOperationByFile = (
	f: FragmentType<typeof DataboxAvailableOperationFragment> | null | undefined,
	userType: UserType
): Partial<FileOperations & FolderOperations> => {
	if (!f) return {}
	const file = useFragment(DataboxAvailableOperationFragment, f)

	const isAdmin = userType === UserType.Admin
	const isTrashed = file.trashed

	// Flag for file
	if (file.nodeType === DataboxNodeType.File) {
		const fileDeletable = isTrashed && isAdmin
		const fileSoftDeletable = !isTrashed && isAdmin
		const fileSoftDeleteAsDeletable =
			!isTrashed && userType === UserType.StoreYb
		const fileRestorable = isTrashed && isAdmin
		const fileDownloadable = file.attachmentUrl
			? { name: file.name, url: file.attachmentUrl }
			: undefined
		const fileViewable =
			file.attachmentUrl && viewableFileTypes.has(file.fileType)
				? { name: file.name, url: file.attachmentUrl }
				: undefined

		return {
			fileDeletable,
			fileSoftDeletable,
			fileSoftDeleteAsDeletable,
			fileRestorable,
			fileDownloadable,
			fileViewable,
		}
	}

	// Flag for folder
	const folderEditable = isAdmin && file.editable
	const folderDownloadable = file.attachmentUrl
		? { url: file.attachmentUrl, name: `${file.name}.zip` }
		: undefined
	const folderDeletable = isTrashed && isAdmin
	const folderRestorable = isTrashed && isAdmin
	const folderSoftDeletable = !isTrashed && isAdmin
	const folderSoftDeleteAsDeletable =
		!isTrashed && userType === UserType.StoreYb

	return {
		folderEditable,
		folderDownloadable,
		folderDeletable,
		folderSoftDeletable,
		folderSoftDeleteAsDeletable,
		folderRestorable,
		showCounts: true,
	}
}

export type DestroyRequiredParams = Pick<
	ResultOf<typeof DataboxDestroyModalFragment>,
	"id" | "name" | "trashed"
>

/** ファイル操作(削除)のモーダルを表示します */
export const useDataboxFileOperationModal = () => {
	const modals = useModals()

	return useCallbackRef(
		(
			item: FragmentType<typeof DataboxDestroyModalFragment>,
			deleteMode: FileOperationMode
		) => {
			const { modalTitle: title, ...messages } =
				operationMessages[messageKey(deleteMode)]
			const target = useFragment(DataboxDestroyModalFragment, item)
			const modalID = modals.openModal({
				title,
				centered: true,
				children: (
					<OperationModalForm
						item={target}
						getID={() => modalID}
						deleteMode={deleteMode}
						{...messages}
					/>
				),
			})
		}
	)
}

const messageKey = (mode: FileOperationMode) => {
	switch (mode) {
		case FileOperationMode.SOFT_DELETE:
			return "soft"
		case FileOperationMode.SOFT_AS_DELETE:
		case FileOperationMode.DELETE:
			return "hard"
		case FileOperationMode.RESTORE:
			return "restore"
		default:
			throw new Error("invalid mode")
	}
}

const operationMessages: Record<
	"soft" | "hard" | "restore",
	OperationModalFormMessageProps
> = {
	soft: {
		modalTitle: "削除済みにする",
		title: "削除済みにしますか？",
		message:
			"「削除済み」としてマークされ、管理者以外のデータBOXに表示されなくなります。",
		label: "削除済みにする",
		color: "grape",
		onError: "削除済みにできませんでした",
		onSuccess: "削除済みにしました",
		onSuccessColor: "grape",
	},
	hard: {
		modalTitle: "ファイルを削除",
		title: "本当に削除しますか？",
		message:
			"一度削除すると、データBOX内での閲覧・ダウンロードが今後できなくなります。",
		label: "削除する",
		color: "red",
		onError: "削除に失敗しました",
		onSuccess: "削除しました",
		onSuccessColor: "gray",
	},
	restore: {
		modalTitle: "ファイルを復元",
		title: "ヨドバシ側に再表示しますか?",
		message: "",
		label: "再表示する",
		onError: "再表示に失敗しました",
		onSuccess: "再表示に成功しました",
		onSuccessColor: "green",
	},
}

type OperationModalFormMessageProps = {
	modalTitle?: string
	title?: string
	message?: string
	label?: string
	color?: string
	onError?: string
	onSuccess?: string
	onSuccessColor?: DefaultMantineColor
}

const DataboxDestroyModalFragment = gql(/* GraphQL */ `
  fragment DataboxDestroyModal on Databox {
    id
    name
    trashed
    nodeType
  }
`)

const DataboxDestroyMutation = gql(/* GraphQL */ `
  mutation DataboxDestroy($id: ID!) {
    deleteDatabox(id: $id) {
      id
      trashed
    }
  }
`)

const DataboxForceDestroyMutation = gql(/* GraphQL */ `
  mutation DataboxForceDestroy($id: ID!) {
    forceDeleteDatabox(id: $id) {
      id
    }
  }
`)
const DataboxRestoreMutation = gql(/* GraphQL */ `
  mutation DataboxRestore($id: ID!) {
    restoreDatabox(id: $id) {
      id
      trashed
    }
  }
`)

const operationMap = {
	[FileOperationMode.SOFT_DELETE]: DataboxDestroyMutation,
	[FileOperationMode.SOFT_AS_DELETE]: DataboxDestroyMutation,
	[FileOperationMode.DELETE]: DataboxForceDestroyMutation,
	[FileOperationMode.RESTORE]: DataboxRestoreMutation,
}

const OperationModalForm = ({
	getID,
	item,
	deleteMode,
	title,
	message,
	label,
	color,
	onSuccess,
	onSuccessColor,
	onError,
}: {
	getID: () => string
	item: DestroyRequiredParams
	deleteMode: FileOperationMode
} & OperationModalFormMessageProps) => {
	const ctx = useModals()
	const [result, exec] = useMutation(operationMap[deleteMode])
	const msg = {
		title,
		message,
		label,
		color,
		onSuccess,
		onError,
		onSuccessColor,
	}

	return (
		<Stack>
			<Box>
				<Alert color={msg.color} title={msg.title}>
					ファイル名: &nbsp;<Mark>{item.name}</Mark>&nbsp;
					<br />
					<br />
					{msg.message}
				</Alert>
			</Box>
			{
				// TODO: エラーメッセージを取得
				result.error && <div>{msg.onError}</div>
			}
			<Group position={"right"}>
				<Button
					variant="default"
					disabled={result.fetching}
					onClick={() => ctx.closeModal(getID(), true)}
				>
					キャンセル
				</Button>

				<Button
					color={msg.color}
					loading={result.fetching}
					onClick={async () => {
						const result = await exec(
							{
								id: item.id,
							},
							{}
						)
						if (!result.error) {
							ctx.closeModal(getID())
							showNotification({
								title: "処理完了",
								message: msg.onSuccess,
								color: msg.onSuccessColor ?? "grape",
								autoClose: 5000,
							})
							return
						}
					}}
				>
					{msg.label}
				</Button>
			</Group>
		</Stack>
	)
}
