import type { MantineThemeOverride } from "@mantine/core"

// $colorDefault: #000;
// $colorSubText: #333333;
// $colorLightText: #707070;
// $colorYellow: #ffd564;
// $colorLightYellow: #fffef4;
// $colorPink: #f76565;
// $colorBlue: #2551e5;
// $colorDarkBlue: #5b90d8;
// $colorLightBlue: #d6e3f2;
// $colorGreen: #83b550;
// $colorRed: #e50112;
// $colorError: #e02121;
// $colorLightGray: #ebe8e8;
// $colorDarkGray: #1e2b38;
// $colorMuddyBlue: #374553;
// $colorDisabled: #e0dfdf;
// $colorWhiteSmoke: #f5f5f5;
// $colorBrilliantWhite: #fffef4;
// $colorWhite: #ffffff;
// /* background */
// $mainSidebarBackground: $colorDarkGray;
// $buttonBlackBackground: $colorDarkGray;
// $resetButtonBlackBackground: $colorWhite;
// $resetButtonHoverBlackBackground: $colorBrilliantWhite;
// $mainHeaderBackground: $colorYellow;
// $sidebarNavListItemListItemLinkBackground: $colorMuddyBlue;
// $loginMainPanelHeaderBackground: $colorMuddyBlue;
// $mainPanelBackground: #f8f8f8;
// $iconBellNumberBackground: $colorPink;
// $checkboxBackground: $colorDarkGray;
// $selectBoxIconBackground: $colorLightText;
//
// /* border */
// $borderColor: $colorSubText;
// $borderLightColor: #e0e0e0;
// $dataBoxListItemIconBorderColor: $colorLightGray;
// $messageListBoxMinItemBorderColor: $colorLightGray;
// $accordionBoxBorderColor: $borderLightColor;
// $detailFooterBorderColor: $borderLightColor;
// $tableBoxBorderColor: #d9d9d9;
// $clientListBorderColor: #d9d9d9;
// $columnBoxIsListBorder: #d9d9d9;
// $checkboxBorder: $colorDarkGray;

/* hover */
// $listItemLinkHoverBackground: $colorLightBlue
// $buttonHoverBackground: $colorLightYellow

export const THEME: MantineThemeOverride = {
	fontFamily: `"Hiragino Kaku Gothic ProN W3", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN", Meiryo, メイリオ, "Noto Sans JP", sans-serif`,
	primaryColor: "blueGray",
	primaryShade: 8,
	colors: {
		// NOTE: 色はデフォルト(OpenColor)から一部変更して元のスキーマに近い色にする
		yellow: [
			"#fff9db",
			"#fff3bf",
			"#ffec99",
			"#ffe066",
			"#ffd564",
			"#fcc419",
			"#fab005",
			"#f59f00",
			"#f08c00",
			"#e67700",
		],
		blueGray: [
			"#ebf7fd",
			"#cfdde4",
			"#b2c3cc",
			"#95aab7",
			"#7790a2",
			"#5d7688",
			"#485a6a",
			"#33404d",
			"#1e2b38",
			"#011115",
		],
	},
}

// font-family: "Hiragino Kaku Gothic ProN W3", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN",
//   Meiryo, メイリオ, "Noto Sans JP", sans-serif;
// font-weight: 400;
