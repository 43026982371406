import { type FragmentType, gql, useFragment } from "@/scripts/generated"
import type {
	DataboxClientPath,
	DataboxFilePath,
	DataboxFolderPath,
} from "@/scripts/generated/graphql"
import { isNode } from "@/scripts/lib/graphql-consumer"
import { isPropertyNot, isTruthy } from "typesafe-utils"
import type { ContentHeaderProps } from "../../../components/common-ui/ContentHeader"

type TFilePath = DataboxClientPath | DataboxFilePath | DataboxFolderPath

/** データボックスのパス情報を使用してパンくずリストを生成する */
export const useDataboxBreadcrumbs = ({
	pathPrefix,
	includeClientName,
	client_id,
	...props
}: {
	pathPrefix?: string | undefined
	includeClientName?: boolean
	path?: FragmentType<typeof DataboxBreadcrumbsFragment>[]
	client_id?: string
}) => {
	const path = useFragment(DataboxBreadcrumbsFragment, props.path) ?? []
	const clientID =
		client_id ?? path?.find((p) => isNode(p, "DataboxClientPath"))?.viewId

	const breadCrumbsFilter = includeClientName
		? () => true
		: isPropertyNot<TFilePath>("__typename", "DataboxClientPath")

	const breadCrumbs: ContentHeaderProps["items"] = [
		{
			label: "データBOX",
			link: {
				to: ["/", pathPrefix, "databox"].filter(isTruthy).join("/"),
			},
		},

		...path.filter(breadCrumbsFilter).map((item) => {
			switch (item.__typename) {
				case "DataboxClientPath":
					return {
						label: item.name,
						link: {
							to: ["/", pathPrefix, "databox", item.viewId]
								.filter(isTruthy)
								.join("/"),
							search: {},
						},
					}
				case "DataboxFolderPath":
					return {
						label: item.name,
						link: {
							to: ["/", pathPrefix, "databox", clientID, item.viewId]
								.filter(isTruthy)
								.join("/"),
							search: {},
						},
					}
				case "DataboxFilePath":
					return {
						label: item.name,
						link: {
							to: ["/", pathPrefix, "databox", "file", item.viewId]
								.filter(isTruthy)
								.join("/"),
							search: {},
						},
					}
			}
		}),
	]

	return { clientID, breadCrumbs }
}

const DataboxBreadcrumbsFragment = gql(/* GraphQL */ `
  fragment DataboxBreadcrumbs on DataboxPathLike {
    id
    viewId
    __typename
    name
  }
`)
