import {
	BackgroundImage,
	Box,
	createStyles,
	type DefaultProps,
	Group,
	Progress,
	type Selectors,
	Stack,
	Text,
} from "@mantine/core"
import type React from "react"
import { FILE_ICON_SRC_MAP } from "../../_common/components/fileIcons"
import { Simulate } from "react-dom/test-utils"

export type UploadProgressProps = {
	progress: UploadProgressState
	previewSrc?: string | null
	label?: string
	fileType: string
	onClickDelete?: () => unknown
} & DefaultProps<UploadProgressStylesNames>

export type UploadProgressState =
	| {
			state: "progress"
			progress: number
	  }
	| { state: "complete" }
	| { state: "failed"; error: React.ReactNode }

const useStyles = createStyles((theme, params: { hasPreview?: boolean }) => ({
	root: {
		alignItems: "end",
	},
	preview: {
		width: 64,
		height: 64,
		position: "relative",
		backgroundColor: params.hasPreview
			? theme.fn.themeColor("gray", 4)
			: undefined,
	},
	previewImage: {
		width: "100%",
		height: "100%",
		backgroundPosition: "center",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
	},
}))

export type UploadProgressStylesNames = Selectors<typeof useStyles>

export const UploadProgress = (props: UploadProgressProps) => {
	const iconSrc = (FILE_ICON_SRC_MAP as Record<string, string>)[props.fileType]

	let progressNum = 0
	if (props.progress.state === "progress") {
		progressNum = props.progress.progress
	} else if (props.progress.state === "complete") {
		progressNum = 100
	} else {
		progressNum = 0
	}
	const error = props.progress.state === "failed" ? props.progress.error : null

	const { classes, cx } = useStyles({ hasPreview: !!props.previewSrc })

	return (
		<Group className={cx(classes.root, props.classNames?.root)}>
			<Box className={cx(classes.preview, props.classNames?.preview)}>
				<BackgroundImage
					className={cx(classes.previewImage, props.classNames?.previewImage)}
					src={props.previewSrc ?? iconSrc}
				/>
			</Box>
			<Stack spacing={"xs"} sx={{ flexGrow: 1 }}>
				<Text size={"sm"}>{props.label}</Text>
				{error && (
					<Text size={"sm"} color={"red"}>
						{error}
					</Text>
				)}
				{!error && (
					<Progress
						animate={!error && progressNum < 0}
						color={"blue"}
						label={`${progressNum}%`}
						size={28}
						radius={"md"}
						value={progressNum}
					/>
				)}
			</Stack>
		</Group>
	)
}
