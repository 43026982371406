import type React from "react"
import { Box, Group, ThemeIcon, type ThemeIconProps } from "@mantine/core"

/** アイコン付きラベル */
export const LabelWithIcon = ({
	...props
}: {
	icon: React.ReactNode
	_icon?: Partial<ThemeIconProps>
	children: React.ReactNode
}) => (
	<Group spacing={0}>
		<ThemeIcon {...props._icon}>{props.icon}</ThemeIcon>
		{props.children}
	</Group>
)
