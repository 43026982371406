import { ResourceViewer } from "@/scripts/contexts/databox/components/ResourceViewer"
import { type FragmentType, gql, useFragment } from "@/scripts/generated"
import { Box, createStyles } from "@mantine/core"
import { useModals } from "@mantine/modals"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"

const useModalStyles = createStyles((_theme) => ({
	inner: {
		padding: "68px 36px",
	},
	modal: {
		background: "transparent",
		border: "none",
		padding: "0 !important",
		pointerEvents: "none",
	},
	body: {
		height: "calc(90vh - 80px)",
	},
}))

/** 拡大表示用のモーダルを開く */
export const useDataboxPreviewModal = () => {
	const modals = useModals()
	const { classes } = useModalStyles()

	return useCallbackRef(
		(itemFragment: FragmentType<typeof DataboxPreviewModalFragment>) => {
			const item = useFragment(DataboxPreviewModalFragment, itemFragment)
			return modals.openModal({
				centered: true,
				size: "full",
				classNames: classes,
				shadow: "none",
				closeOnEscape: true,
				closeOnClickOutside: true,
				withCloseButton: false,
				children: (
					<Box
						sx={{
							height: "100%",
							textAlign: "center",
						}}
					>
						<ResourceViewer
							item={item}
							style={{
								width: "auto",
								height: "100%",
								objectFit: "contain",
							}}
						/>
					</Box>
				),
			})
		}
	)
}

const DataboxPreviewModalFragment = gql(/* GraphQL */ `
  fragment DataboxPreviewModal on Databox {
    attachmentUrl
    thumbnailImageUrl
    fileType
    name
  }
`)
