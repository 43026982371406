import type React from "react"

export const DataboxLayoutPanel = (props: { children?: React.ReactNode }) => (
	<div className="adminDataBox__panel">{props.children}</div>
)
export const DataboxLayoutPanelMain = (props: {
	children?: React.ReactNode
}) => <div className="adminDataBox__panelMain">{props.children}</div>
export const DataboxLayoutPanelSidebar = (props: {
	children?: React.ReactNode
}) => <div className="adminDataBox__panelSidebar">{props.children}</div>
