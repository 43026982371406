import ImgFolder from "@/images/icon/icon--addFolder.svg?react"
import ImgFile from "@/images/icon/icon--addFile.svg?react"
import ImgUpload from "@/images/icon/icon--upload.svg?react"
import { DataboxNodeType } from "@/scripts/generated/graphql"
import { Button, ChevronIcon, Menu } from "@mantine/core"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"
import type { ComponentPropsWithoutRef } from "react"

export type UploadButtonProps = {
	onClickUpload?: (type: DataboxNodeType) => unknown
}

export const UploadButton = (props: UploadButtonProps) => {
	const onClick = useCallbackRef<
		Required<ComponentPropsWithoutRef<"button">>["onClick"]
	>((e) => {
		const type = e.currentTarget.dataset["type"] as DataboxNodeType
		props.onClickUpload?.(type)
	})

	return (
		<Menu transitionProps={{ transition: "scale-y" }}>
			<Menu.Target>
				<Button
					size={"md"}
					rightIcon={<ChevronIcon />}
					leftIcon={<ImgUpload />}
				>
					アップロード
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					data-type={DataboxNodeType.Folder}
					onClick={onClick}
					icon={<ImgFolder />}
				>
					フォルダ
				</Menu.Item>
				<Menu.Item
					data-type={DataboxNodeType.File}
					onClick={onClick}
					icon={<ImgFile />}
				>
					ファイル
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	)
}
