import { useCallback, useRef } from "react"

export const useHandlerRef = () => {
	const ref = useRef<WeakMap<any, any>>()

	const getEventHandler = useCallback(<T,>(handler: T, key: any): T => {
		ref.current ??= new WeakMap()
		let cached = ref.current.get(key)
		if (!cached) {
			cached = handler
			ref.current.set(key, handler)
		}
		return cached
	}, [])

	return [getEventHandler]
}
