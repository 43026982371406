import { useCurrentUser } from "@/scripts/contexts/auth/context"
import { useFileCreateModal } from "@/scripts/contexts/databox/components/useFileCreateModal"
import { useFolderCreateModal } from "@/scripts/contexts/databox/components/useFolderCreateModal"
import { gql, useFragment } from "@/scripts/generated"
import {
	type DataboxGridPageListQueryVariables,
	type DataboxGridPageQueryVariables,
	DataboxNodeType,
	UserType,
} from "@/scripts/generated/graphql"
import { isNode } from "@/scripts/lib/graphql-consumer"
import type { ResultOf } from "@graphql-typed-document-node/core"
import { useToggle } from "@mantine/hooks"
import {
	type MakeGenerics,
	useNavigate,
	useSearch,
} from "@tanstack/react-location"
import { isIOS, isMacOs } from "mobile-device-detect"
import { useEffect, useMemo, useState } from "react"
import { isTruthy } from "typesafe-utils"
import { useQuery } from "urql"
import {
	ContentHeader,
	ContentHeaderLeftItem,
	ContentHeaderRight,
	ContentHeaderRightItem,
} from "../../../components/common-ui/ContentHeader"
import {
	SearchForm,
	type SearchFormProps,
} from "../../../components/common-ui/SearchForm"
import {
	Content,
	ContentSection,
} from "../../../components/layout/ApplicationLayout"
import type { DataboxDisplayMode } from "../components/DataboxDisplayMode"
import {
	DataboxFileInfo,
	type DataboxFileInfoItem,
} from "../components/DataboxFileInfo"
import { DataboxHeaderInfo } from "../components/DataboxHeaderInfo"
import {
	type DataboxItem,
	DataboxItemListWithPagination,
	type DataboxItemWithPaginationProps,
} from "../components/DataboxItemList"
import { DisplayStyleToggleButton } from "../components/DisplayStyleToggleButton"
import { UploadButton } from "../components/UploadButton"
import {
	DataboxLayoutPanel,
	DataboxLayoutPanelMain,
	DataboxLayoutPanelSidebar,
} from "../components/layout"
import { useDataboxBreadcrumbs } from "../components/useDataboxBreadcrumbs"

export type Route = MakeGenerics<{
	Search: {
		name?: string
	}
	Params: {
		folder_id: string
		client_id: string
	}
}>

type DataboxGridPageProps = {
	databoxInfo?: boolean
	pathPrefix?: string
	includeClientName?: boolean
	folder_id: string
	client_id: string
}

/**
 * データボックス一覧表示ページ
 *
 * - 検索パラメーターがある場合は検索結果を表示します。
 * - 検索パラメーターが無い場合も常にその階層の一覧データは取得します。
 */
export const DataboxGridPage = (props: DataboxGridPageProps) => {
	const routeParams = useDataboxGridPageRoute(props)
	return <DataboxGridPageContent {...props} {...routeParams} />
}

export const useDataboxGridPageRoute = (props: DataboxGridPageProps) => {
	const folder_id = props.folder_id
	const client_id = props.client_id

	// NOTE: ページ遷移時にprefetchしたいなー
	const search = useSearch<Route>()
	const searchQuery = (search.name ?? "").toString()
	const isSearch = isTruthy(searchQuery)

	const databoxViewId = folder_id === "root" ? client_id : folder_id
	const metadataQueryVariable: DataboxGridPageQueryVariables = {
		id: databoxViewId,
	}
	const listQueryVariable: DataboxGridPageListQueryVariables = useMemo(
		() => ({
			id: databoxViewId,
			after: "",
			first: 12,
			search: isSearch ? searchQuery : null,
			includeDescendant: isSearch,
		}),
		[isSearch, searchQuery, databoxViewId]
	)

	return {
		search,
		folder_id,
		client_id,
		databoxViewId,
		metadataQueryVariable,
		listQueryVariable,
		isSearch,
		searchQuery,
	}
}

const getListItem: DataboxItemWithPaginationProps<
	typeof DataboxGridPageListQueryDoc,
	ResultOf<typeof DataboxGridPageDataboxFragment>
>["getListItem"] = (ret) => {
	if (!ret?.databoxView?.items?.pageInfo) return undefined

	const nodes = useFragment(
		DataboxGridPageDataboxFragment,
		ret.databoxView.items?.edges.map((e) => e.node) ?? []
	) as any as any[]
	const pageInfo = ret.databoxView.items.pageInfo
	return {
		nodes,
		pageInfo,
	}
}

type DataboxGridPageContentProps = DataboxGridPageProps &
	ReturnType<typeof useDataboxGridPageRoute>

const DataboxGridPageContent = ({
	metadataQueryVariable,
	listQueryVariable,
	client_id,
	folder_id,
	pathPrefix,
	databoxViewId,
	includeClientName,
	databoxInfo,
	searchQuery,
}: DataboxGridPageContentProps) => {
	const navigate = useNavigate<Route>()
	const policy = useDataboxListPolicy()

	const [{ data }] = useQuery({
		query: DataboxGridPageQueryDoc,
		variables: metadataQueryVariable,
		context: useMemo(() => ({ suspense: false }), []),
	})
	const gridPage = useFragment(DataboxGridPageFragment, data?.databoxView)

	// パス
	const { clientID, breadCrumbs } = useDataboxBreadcrumbs({
		pathPrefix,
		path: gridPage?.path ?? [],
		client_id,
		includeClientName: includeClientName,
	})

	// 検索
	const onSubmitSearch: SearchFormProps["onSubmit"] = (values, event) => {
		event.preventDefault()
		navigate({ search: (old) => ({ ...old, name: values.search }) })
	}

	// 表示切り替え
	const [currentMode, onToggleMode] = useToggle<DataboxDisplayMode>([
		"grid",
		"list",
	])
	const { selectedItem: selectedItems, onSelect } = useDataboxGridSelection(
		gridPage,
		pathPrefix,
		clientID,
		folder_id
	)

	// アップロード
	const handleUpload = useUploadModal({
		databoxViewId,
	})

	return (
		<Content>
			<ContentSection>
				<ContentHeader items={breadCrumbs} />
				{/* 統計情報 */}
				{databoxInfo && <DataboxHeaderInfo file={gridPage} />}

				{/* 検索, 表示トグル */}
				<ContentHeaderRight>
					<ContentHeaderLeftItem>
						{policy.uploadButton && (
							<UploadButton onClickUpload={handleUpload} />
						)}
					</ContentHeaderLeftItem>
					<ContentHeaderRightItem>
						<SearchForm
							key={`${listQueryVariable.id}-${listQueryVariable.search}`}
							defaultValue={searchQuery}
							placeholder={"フォルダ以下から検索"}
							onSubmit={onSubmitSearch}
						/>
					</ContentHeaderRightItem>
					<ContentHeaderRightItem>
						<DisplayStyleToggleButton
							mode={currentMode}
							onToggle={onToggleMode}
						/>
					</ContentHeaderRightItem>
				</ContentHeaderRight>

				{/* 本体 */}
				<DataboxLayoutPanel>
					<DataboxLayoutPanelMain>
						<DataboxItemListWithPagination
							useQuery={DataboxGridPageListQueryDoc}
							key={`${listQueryVariable.id}-${listQueryVariable.search}`}
							variables={listQueryVariable}
							mode={currentMode}
							selectedNodeIds={selectedItems.map((i) => i.id)}
							onClickItem={onSelect}
							getListItem={getListItem}
						/>
					</DataboxLayoutPanelMain>
					<DataboxLayoutPanelSidebar>
						<DataboxFileInfo files={selectedItems as DataboxFileInfoItem[]} />
					</DataboxLayoutPanelSidebar>
				</DataboxLayoutPanel>
			</ContentSection>
		</Content>
	)
}

function useDataboxGridSelection(
	view: ResultOf<typeof DataboxGridPageFragment> | undefined | null,
	pathPrefix: string | undefined,
	clientID: string | undefined,
	folder_id: string | undefined
) {
	const navigate = useNavigate<Route>()

	// 選択したら詳細へ
	const [selectedItems, setSelectedItems] = useState<DataboxItem[]>([])

	const onSelect: NonNullable<
		DataboxItemWithPaginationProps<any>["onClickItem"]
	> = (item: DataboxItem, e) => {
		if (!view?.path) return

		const withSelectKey = isIOS || isMacOs ? e.metaKey : e.ctrlKey
		setSelectedItems((current) => {
			const idx = current.findIndex((v) => v.id === item.id)
			const included = idx !== -1
			const oneItem = current.length === 1

			// 別のItemをクリックした場合 + Ctrl : 選択追加
			// 別のItemをクリックした場合 + !Ctrl : 新しいものだけ選択
			// 含まれるItemをクリックした場合 + !Ctrl & 1件のみ選択: 遷移
			// 含まれるItemをクリックした場合 + Ctrl : 選択解除
			// 含まれるItemをクリックした場合 + !Ctrl : 1件のみ選択
			if (!included) {
				return withSelectKey ? [...current, item] : [item]
			} else {
				if (!withSelectKey && oneItem) {
					if (
						isNode(item, "Databox") &&
						item.nodeType === DataboxNodeType.File
					) {
						navigate({
							to: ["/", pathPrefix, "databox", "file", item.id]
								.filter(isTruthy)
								.join("/"),
						})
					} else {
						navigate({
							to: ["/", pathPrefix, "databox", clientID, item.id]
								.filter(isTruthy)
								.join("/"),
						})
					}
					return current
				} else {
					return withSelectKey
						? [...current.slice(0, idx), ...current.slice(idx + 1)]
						: [item]
				}
			}
		})

		return
	}

	// ページ遷移時に選択解除
	useEffect(() => {
		setSelectedItems([])
	}, [clientID, folder_id])
	return { selectedItem: selectedItems, onSelect }
}

const useUploadModal = (params: { databoxViewId: string }) => {
	const folderCreate = useFolderCreateModal()
	const fileCreate = useFileCreateModal()

	return (type: DataboxNodeType) => {
		if (type === DataboxNodeType.Folder) {
			folderCreate({
				params,
			})
		} else if (type === DataboxNodeType.File) {
			fileCreate({
				params,
			})
		}
	}
}

/** データボックスの一覧表示画面で使用できるコンポーネントを返すhooks */
const useDataboxListPolicy = () => {
	const [_, userType] = useCurrentUser()
	return {
		uploadButton: userType === UserType.Admin,
	}
}

const DataboxGridPageQueryDoc = gql(/* GraphQL */ `
  query DataboxGridPage($id: ID!) {
    databoxView(id: $id) {
      ...DataboxGridPage
    }
  }
`)

const DataboxGridPageListQueryDoc = gql(/* GraphQL */ `
  query DataboxGridPageList($id: ID!, $search: String, $includeDescendant: Boolean, $first: Int!, $after: String) {
    databoxView(id: $id) {
      items(filterByName: $search, includeDescendant: $includeDescendant, first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }

        edges {
          node {
            ...DataboxGridPageDatabox
          }
        }
      }
    }
  }
`)

const DataboxGridPageFragment = gql(/* GraphQL */ `
  fragment DataboxGridPage on DataboxView {
    __typename
    id
    path {
      ...DataboxBreadcrumbs
    }
    ...DataboxHeaderInfo
  }
`)

const DataboxGridPageDataboxFragment = gql(/* GraphQL */ `
  fragment DataboxGridPageDatabox on Databox {
    id
    dbId
    name
    nodeType
    fileType
    trashed
    __typename

    ...DataboxFileList
    ...DataboxFileFileInfo
  }
`)
