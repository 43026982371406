/**
 * ファイルアイコンのSVG
 *
 * TODO: 基本的なデザインは同じで、文字が違うだけなのでSVGコンポーネントにできそう。
 */
import fileIconai from "@/images/icon/file_ai.svg"
import fileIconbmp from "@/images/icon/file_bmp.svg"
import fileIconcab from "@/images/icon/file_cab.svg"
import fileIcondoc from "@/images/icon/file_doc.svg"
import fileIcondocx from "@/images/icon/file_docx.svg"
import fileIconeps from "@/images/icon/file_eps.svg"
import fileIcongif from "@/images/icon/file_gif.svg"
import fileIconjpeg from "@/images/icon/file_jpeg.svg"
import fileIconlzh from "@/images/icon/file_lzh.svg"
import fileIconpdf from "@/images/icon/file_pdf.svg"
import fileIconpng from "@/images/icon/file_png.svg"
import fileIconppt from "@/images/icon/file_ppt.svg"
import fileIconpptx from "@/images/icon/file_pptx.svg"
import fileIconpsd from "@/images/icon/file_psd.svg"
import fileIconrar from "@/images/icon/file_rar.svg"
import fileIconsit from "@/images/icon/file_sit.svg"
import fileIcontar from "@/images/icon/file_tar.svg"
import fileIcontga from "@/images/icon/file_tga.svg"
import fileIcontif from "@/images/icon/file_tif.svg"
import fileIcontiff from "@/images/icon/file_tiff.svg"
import fileIconxls from "@/images/icon/file_xls.svg"
import fileIconxlsx from "@/images/icon/file_xlsx.svg"
import fileIconzip from "@/images/icon/file_zip.svg"

import { MIME_TYPES } from "@/scripts/contexts/_common/lib/fileTypes"
import type { ValueOf } from "type-fest"

export const FILE_ICON_SRC_MAP: Partial<
	Record<ValueOf<typeof MIME_TYPES>, string>
> = {
	[MIME_TYPES.png]: fileIconpng,
	[MIME_TYPES.jpeg]: fileIconjpeg,
	[MIME_TYPES.jpg]: fileIconjpeg,
	[MIME_TYPES.gif]: fileIcongif,
	[MIME_TYPES.bmp]: fileIconbmp,
	[MIME_TYPES.tif]: fileIcontif,
	[MIME_TYPES.tiff]: fileIcontiff,
	[MIME_TYPES.tga]: fileIcontga,
	[MIME_TYPES.psd]: fileIconpsd,
	[MIME_TYPES.eps]: fileIconeps,
	[MIME_TYPES.ai]: fileIconai,
	[MIME_TYPES.doc]: fileIcondoc,
	[MIME_TYPES.docx]: fileIcondocx,
	[MIME_TYPES.ppt]: fileIconppt,
	[MIME_TYPES.pptx]: fileIconpptx,
	[MIME_TYPES.xls]: fileIconxls,
	[MIME_TYPES.xlsx]: fileIconxlsx,
	[MIME_TYPES.zip]: fileIconzip,
	[MIME_TYPES.rar]: fileIconrar,
	[MIME_TYPES.sit]: fileIconsit,
	[MIME_TYPES.tar]: fileIcontar,
	[MIME_TYPES.cab]: fileIconcab,
	[MIME_TYPES.lzh]: fileIconlzh,
	[MIME_TYPES.pdf]: fileIconpdf,
}
