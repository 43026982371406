import type { LinkAnchor } from "@/scripts/components/common-ui/types"
import { NavLink } from "@/scripts/components/common-ui/NavLink"
import type React from "react"

export type ContentHeaderProps = {
	items: { label: string; link?: LinkAnchor; statusLabel?: string }[]
	button?: React.ReactNode
}

export const ContentHeader = (props: ContentHeaderProps) => (
	<header className="contentHeader">
		<ul className="contentHeader__titleList">
			{props.items.map((title, index, items) => {
				const Wrapper = (
					items.length - 1 === index ? "h2" : "p"
				) as keyof JSX.IntrinsicElements
				return (
					<li key={index} className="contentHeader__titleListItem">
						<Wrapper className="contentHeader__titleListItemText">
							{title.link ? (
								<NavLink href={title.link}>{title.label}</NavLink>
							) : (
								title.label
							)}
						</Wrapper>
					</li>
				)
			})}
		</ul>

		{props.button && (
			<div className="contentHeader__button">{props.button}</div>
		)}
	</header>
)

export const ContentHeaderRight = (props: { children?: React.ReactNode }) => (
	<div className="contentHeader contentHeader--right">{props.children}</div>
)

export const ContentHeaderRightItem = (props: {
	children?: React.ReactNode
}) => <div className="contentHeader--right__item">{props.children}</div>

export const ContentHeaderLeftItem = (props: {
	children?: React.ReactNode
}) => (
	<div className="contentHeader contentHeader--right__left">
		{props.children}
	</div>
)
