import { createRoot } from "react-dom/client"

type ComponentLoader = () => Promise<React.ComponentType<any>>
const componentRegistry = new Map<string, ComponentLoader>()

const FallbackComponent = (props: { name: string }) => {
	return (
		<div style={{ background: "red", color: "white", padding: "1em 2em" }}>
			Component not found: {props.name}
		</div>
	)
}

class MPAReactLoader extends HTMLElement {
	mountPoint?: HTMLDivElement
	reactRoot?: ReturnType<typeof createRoot>

	async connectedCallback() {
		const name = this.tagName.replace(/^REACT-/, "")
		let props = this.props
		let loader = componentRegistry.get(name ?? "")
		if (!loader) {
			loader = () => Promise.resolve(FallbackComponent)
			props = { name: name ?? "(empty value)" }
		}

		const shadow = this.attachShadow({ mode: "open" })
		this.mountPoint = document.createElement("div")
		shadow.appendChild(this.mountPoint)
		this.reactRoot = createRoot(this.mountPoint)

		const Component = await loader()
		this.reactRoot.render(<Component {...props} />)
	}

	get props() {
		const props = Array.from(this.attributes).reduce(
			(props, { name, value }) => {
				props[name] = value
				return props
			},
			{} as Record<string, any>
		)
		return props
	}

	disconnectedCallback() {
		this.reactRoot?.unmount()
	}
}

/** ReactコンポーネントのエントリーポイントになるCustomElementを登録します。*/
export const registerMPAReactEntrypoint = (
	components: Record<string, ComponentLoader>
) => {
	Object.entries(components).forEach(([name, loader]) => {
		componentRegistry.set(name.toUpperCase(), loader)
		customElements.define(`react-${name}`, MPAReactLoader)
	})
}
