import { PageTitle } from "@/scripts/components/common-ui/PageTitle"
import { gqlClient } from "@/scripts/contexts/networking/graphql-client"
import { MantineProvider, createEmotionCache } from "@mantine/core"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import * as Sentry from "@sentry/react"
import type { FallbackRender } from "@sentry/react"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { ReactLocationDevtools } from "@tanstack/react-location-devtools"
import type React from "react"
import { HelmetProvider } from "react-helmet-async"
import { Provider as URQLProvider } from "urql"
import { routes } from "../routes"
import { THEME } from "../theme"

const location = new ReactLocation()

export const App = () => {
	return (
		<Sentry.ErrorBoundary fallback={ErrorFallback}>
			<SystemsProvider>
				<UIProvider>
					<PageTitle />
					<Router location={location} basepath={"/v2"} routes={routes}>
						<Outlet />
						{import.meta.env.DEV && <ReactLocationDevtools />}
					</Router>
				</UIProvider>
			</SystemsProvider>
		</Sentry.ErrorBoundary>
	)
}

const ErrorFallback: FallbackRender = ({ eventId, error }) => {
	const msg =
		typeof error === "object" &&
		error !== null &&
		"message" in error &&
		typeof error.message === "string"
			? error.message
			: "不明なエラー"

	return (
		<div
			style={{
				padding: "1em",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				background: "#eee",
			}}
		>
			<div
				style={{
					// display: "flex",
					// flexDirection: "column",
					// alignItems: "center",
					// justifyContent: "center",
					minWidth: 300,
					background: "#fff",
					padding: "2em",
					fontSize: "16px",
					borderRadius: 20,
					color: "#474747",
					lineHeight: "1.5",
				}}
			>
				<h1
					style={{ textAlign: "center", fontSize: "24px", marginBottom: "1em" }}
				>
					このページはご利用いただけません
				</h1>
				<p>
					ご迷惑をおかけしております。
					<br />
					予期しないエラーが発生したため、ご利用することができません。
				</p>
				<hr />
				<ul style={{ fontSize: "0.8em", color: "#777" }}>
					<li>URL: {window.location.href}</li>
					<li>Error ID: {eventId}</li>
					<li>Message: {msg}</li>
				</ul>
			</div>
		</div>
	)
}
const SystemsProvider = (props: { children?: React.ReactNode }) => (
	<URQLProvider value={gqlClient}>{props.children}</URQLProvider>
)

const emotionCache = createEmotionCache({ key: "tm" })

const UIProvider = (props: { children?: React.ReactNode }) => (
	<HelmetProvider>
		<MantineProvider
			theme={THEME}
			withCSSVariables
			// NOTE: React化できたらこちらを使用する
			// withNormalizeCSS
			// withGlobalStyles
			emotionCache={emotionCache}
		>
			<Notifications position="bottom-center" />
			<ModalsProvider>{props.children}</ModalsProvider>
		</MantineProvider>
	</HelmetProvider>
)
