import type { SidebarNavProps } from "@/scripts/components/layout/SidebarNav"
import ImgIconImg from "@/images/icon/icon--img.svg?react"
import ImgPeople from "@/images/icon/icon--group.svg?react"
import ImgBuilding from "@/images/icon/icon--building.svg?react"
import React from "react"
import ImgBox from "@/images/icon/icon--folder.svg?react"

export const AdminPageSidebarNav: SidebarNavProps["sections"] = [
	{
		t: "section",
		key: "materials",
		label: "販促物依頼",
		labelIcon: <ImgIconImg />,
		items: [
			{
				t: "item",
				label: "進行中の販促物依頼",
				key: "ongoing",
				match:
					// { to: "/admin/material" }
					"/admin/material",
			},
			{
				t: "item",
				label: "完了した販促物依頼",
				key: "done",
				match:
					// { to: "/admin/material/completed" }
					"/admin/material/completed",
			},
		],
	},
	{
		t: "section",
		key: "human-resources",
		label: "人材依頼",
		labelIcon: <ImgPeople />,
		items: [
			{
				t: "item",
				label: "進行中の人材依頼",
				key: "ongoing",
				match:
					// { to: "/admin/human-resource" }
					"/admin/human-resource",
			},
			{
				t: "item",
				label: "完了した人材物依頼",
				key: "done",
				match:
					// { to: "/admin/human-resource/completed" }
					"/admin/human-resource/completed",
			},
		],
	},
	{
		t: "section",
		key: "clients",
		label: "クライアント管理",
		labelIcon: <ImgBuilding />,
		items: [
			{
				t: "item",
				label: "クライアント追加",
				key: "add",
				match:
					// { to: "/admin/client-list/create" }
					"/admin/client-list/create",
			},
			{
				t: "item",
				label: "クライアントリスト",
				key: "list",
				match:
					// { to: "/admin/client-list" }
					"/admin/client-list",
			},
		],
	},
]
export const AdminPageSidebarNavButtons: SidebarNavProps["itemsAfterSections"] =
	[
		{
			t: "item",
			key: "data-box",
			label: "データBOX",
			labelIcon: <ImgBox />,
			match: { to: "/admin/databox" },
		},
	]
