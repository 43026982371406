import {
	ApplicationLayout,
	Content,
	DetailFooterWrap,
	Footer,
	Header,
	Main,
	Sidebar,
} from "@/scripts/components/layout/ApplicationLayout"
import type React from "react"
import { AdminPageSidebarNav, AdminPageSidebarNavButtons } from "./Sidebar"
import { HeaderNav } from "./HeaderNav"
import { Box } from "@mantine/core"

export const Layout = (props: React.PropsWithChildren<{}>) => {
	return (
		<ApplicationLayout>
			<Sidebar
				sections={AdminPageSidebarNav}
				itemsAfterSections={AdminPageSidebarNavButtons}
			/>
			<Main>
				<Header>
					<HeaderNav />
				</Header>
				<Box sx={{ width: "100%", height: "100%", position: "relative" }}>
					{props.children}
					<DetailFooterWrap />
				</Box>
			</Main>
		</ApplicationLayout>
	)
}

export const LayoutContent = Content
export const LayoutFooter = Footer
