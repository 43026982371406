import React from "react"
import type { Databox } from "@/scripts/generated/graphql"

export const ResourceViewer = (props: {
	item: Pick<Databox, "fileType" | "thumbnailImageUrl" | "attachmentUrl">
	style?: any
}) => {
	if (props.item.fileType === "pdf") {
		return <embed src={props.item.attachmentUrl!} style={props.style} />
	}
	return (
		<img
			alt=""
			src={props.item.thumbnailImageUrl! || props.item.attachmentUrl!}
			style={props.style}
		/>
	)
}
