import { useEffect, useState } from "react"

/** Fileのプレビュー画像用URLを作成する */
export const useFileObjectURL = (file: File | undefined | null) => {
	const [preview, setPreview] = useState<string | null>(null)
	useEffect(() => {
		if (!file) return

		const preview = URL.createObjectURL(file)
		setPreview(preview)
		return () => {
			URL.revokeObjectURL(preview)
			setPreview(null)
		}
	}, [file])
	return preview
}
