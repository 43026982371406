import imgLogo from "@/images/common/logo.svg"
import ImgBox from "@/images/icon/icon--folder.svg?react"
import { useAppShellStyle } from "@/scripts/components/layout/ApplicationLayout"
import {
	LargeMenuLink,
	MenuLink,
	SidebarNav,
	type SidebarNavProps,
} from "@/scripts/components/layout/SidebarNav"
import { useDataboxGridPageRoute } from "@/scripts/contexts/databox/pages/DataboxGridPage"
import { gql } from "@/scripts/generated"
import { useMatches } from "@tanstack/react-location"
import { useMemo } from "react"
import { useQuery } from "urql"
import { LabelWithIcon } from "../../../components/common-ui/LabelWithIcon"

export const DataboxSidebar = (props: Pick<SidebarNavProps, "footerItems">) => {
	const matches = useMatches()
	const match = matches[matches.length - 1]
	const { client_id, folder_id = "root" } = match?.params || {}

	const { databoxViewId } = useDataboxGridPageRoute({
		client_id,
		folder_id,
	})

	const [{ data }] = useQuery({
		query: DataboxSidebarQuery,
		variables: {
			id: databoxViewId,
		},
		pause: !client_id,
		context: useMemo(() => ({ suspense: false }), []),
	})
	const items = data?.databoxView?.items?.edges?.map(({ node }) => node) ?? []

	const { classes, cx } = useAppShellStyle()
	return (
		<aside
			id="mainSidebar"
			className={cx(
				"mainSidebar wrapper__item wrapper__item--mainSidebar",
				classes.sidebar
			)}
		>
			<a href="/" className="mainSidebar__logoLink wrapper__itemHead">
				<h1 className="mainSidebar__logo">
					<img className="mainSidebar__logoImage" src={imgLogo} alt="Tomas" />
				</h1>
			</a>
			<SidebarNav
				sections={
					<>
						<LargeMenuLink
							match={{
								to: "/databox",
								from: match,
							}}
							label={
								<LabelWithIcon
									icon={<ImgBox />}
									_icon={{
										size: 32,
									}}
								>
									データBOX
								</LabelWithIcon>
							}
						/>
						{items.map((node) => (
							<MenuLink
								key={node.id}
								label={node.name}
								match={{
									to: "../" + node.asView.itemID,
									from: match,
								}}
							/>
						))}
					</>
				}
				footerItems={props.footerItems}
			/>
		</aside>
	)
}

// GridPageと同様のクエリだが、サイドバーにはフォルダのみ、件数多めで取得する
const DataboxSidebarQuery = gql(/* GraphQL */ `
  query DataboxSidebar($id: ID!) {
    databoxView(id: $id) {
      __typename
      id

      items(first: 100, nodeType: FOLDER) {
        pageInfo {
          hasNextPage
          endCursor
        }

        edges {
          node {
            __typename
            ... on Databox {
              name
              id
              nodeType
              asView {
                id
                itemID
              }
            }
          }
        }
      }
    }
  }
`)
