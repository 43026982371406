import { isProperty } from "typesafe-utils"
type TypeNamed<T extends string = string> = { __typename: T }

export const isNode = <N extends string>(
	node: unknown,
	typename: N
): node is NonNullable<TypeNamed<N>> =>
	typeof node === "object" &&
	node !== null &&
	"__typename" in node &&
	(node as TypeNamed).__typename === typename
