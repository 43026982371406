import ImgIconUpload from "@/images/icon/icon--uploadBlack.svg?react"
import ImgIconDelete from "@/images/icon/delete.svg?react"
import { Stack, Text } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import type { ComponentProps } from "react"
import { SizedIcon } from "../../_common/components/SizedIcon"

type DNDFileUploadProps = {
	label?: string
	clickLabel?: string
} & Pick<
	ComponentProps<typeof Dropzone>,
	"onDrop" | "onReject" | "maxSize" | "accept"
>

export const DNDFileUpload = (props: DNDFileUploadProps) => {
	return (
		<Dropzone {...props}>
			<Stack align={"center"} sx={{ pointerEvents: "none" }}>
				<SizedIcon size={48}>
					<Dropzone.Accept>
						<ImgIconUpload />
					</Dropzone.Accept>
					<Dropzone.Reject>
						<ImgIconDelete />
					</Dropzone.Reject>
					<Dropzone.Idle>
						<ImgIconUpload />
					</Dropzone.Idle>
				</SizedIcon>

				<Stack align={"center"}>
					<Text weight={"bold"} size={"xl"}>
						{props.label ?? "ここにファイルをドロップ"}
					</Text>
					<Text size={"md"}>または</Text>
					<Text
						weight={"bold"}
						size={"xl"}
						variant={"link"}
						color={"blue"}
						underline
					>
						{props.clickLabel ?? "クリックしてファイルを選択"}
					</Text>
				</Stack>
			</Stack>
		</Dropzone>
	)
}
