import { Link } from "@tanstack/react-location"
import type { LinkAnchor } from "@/scripts/components/common-ui/types"
import type React from "react"

export const NavLink = ({
	href,
	...props
}: React.PropsWithChildren<{ href: LinkAnchor }>) => {
	if (typeof href === "string") {
		return <a {...props} href={href} />
	}

	return <Link {...props} to={href.to} />
}
