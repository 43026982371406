import React, {
	type MutableRefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"
import {
	type MultipartUploaderProgress,
	type UploaderEvent,
	type UploaderEventComplete,
	type UploaderEventHandler,
	uploadFile,
} from "../../upload/MultipartUploader"
import { useAbortController } from "./useAbortController"
import type { UploadProgressState } from "../../upload/components/UploadProgress"
import { getFileUUID } from "../../upload/getFileUUID"
import { isProperty, pick } from "typesafe-utils"

export type UploaderQueueRef = MutableRefObject<
	Map<string, MultipartUploaderProgress> | undefined
>

/** ファイルをアップロードします
 * - 結果がprogressに格納されます
 * - アンマウント時に中断されます
 */
export const useFileUploader = (fileUploadQueueFromUser?: UploaderQueueRef) => {
	const fileUploadQueue =
		fileUploadQueueFromUser ?? useRef<Map<string, MultipartUploaderProgress>>()
	const abortUpload = useAbortController()
	const [files, setFiles] = useState<File[]>(() => {
		if (fileUploadQueue.current) {
			return Array.from(fileUploadQueue.current.values()).map(
				({ file }) => file
			)
		}
		return []
	})
	const [progress, setProgress] = useState<Record<string, UploadProgressState>>(
		() => {
			if (fileUploadQueue.current) {
				return Array.from(fileUploadQueue.current.values()).reduce(
					(acc, value) => {
						acc[getFileUUID(value.file)] = uploaderEventToUploadProgress(
							value.status
						)
						return acc
					},
					{} as Record<string, UploadProgressState>
				)
			}
			return {}
		}
	)

	const handleUploadProgress = useCallback<UploaderEventHandler>((evt) => {
		setProgress((current) => ({
			...current,
			[getFileUUID(evt.meta.file)]: uploaderEventToUploadProgress(evt),
		}))
	}, [])

	// ファイルが追加されるたびにアップロードを開始
	useEffect(() => {
		if (!fileUploadQueue.current)
			fileUploadQueue.current = new Map<string, MultipartUploaderProgress>()
		for (const file of files) {
			if (fileUploadQueue.current.has(getFileUUID(file))) continue

			console.log("start uploading file:", file.name)
			const uploader = uploadFile(file)
			uploader.on(handleUploadProgress)
			fileUploadQueue.current.set(getFileUUID(file), uploader)
			void uploader.start(abortUpload.current)
		}
	}, [files])

	return {
		files,
		setFiles,
		progress,
	}
}

const uploaderEventToUploadProgress = (
	evt: UploaderEvent
): UploadProgressState => {
	switch (evt.type) {
		case "complete":
			return {
				state: "complete",
			}
		case "error":
			return {
				state: "failed",
				error: <>アップロードに失敗しました</>,
			}
		case "progress":
			return {
				state: "progress",
				progress: evt.progress,
			}
		case "setup":
		case "init":
			return {
				state: "progress",
				progress: 0,
			}
	}
}

/** UploaderQueueRefをファイルパスの配列に変換します */
export const queueToCompletedFiles = (
	ref: UploaderQueueRef
): Pick<UploaderEventComplete["result"], "preview_url" | "storage_path">[] =>
	ref.current
		? Array.from(ref.current.values())
				.map(pick("status"))
				.filter(isProperty("type", "complete"))
				.map((file: any) => file.result)
		: []
