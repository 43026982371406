import { type BoxProps, Center, createStyles, Loader } from "@mantine/core"
import { useIntersection } from "@mantine/hooks"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"
import { useEffect } from "react"

export const InfiniteLoader = ({
	loading,
	onLoadNext = noop,
	...props
}: { loading?: boolean; onLoadNext?: () => unknown } & BoxProps) => {
	const { ref, entry } = useIntersection({
		root: null,
		rootMargin: "200px",
	})
	const onLoadNextHandler = useCallbackRef(onLoadNext)
	const visible = entry?.isIntersecting ?? false

	useEffect(() => {
		if (loading) return
		if (!visible) return

		onLoadNextHandler?.()
	}, [visible, loading, onLoadNextHandler])
	const { classes, cx } = useLoaderStyle({ visible: !!entry?.isIntersecting })
	return (
		<Center ref={ref} {...props} className={cx(classes.root, props.className)}>
			{visible && <Loader />}
		</Center>
	)
}
const noop = () => {}
const useLoaderStyle = createStyles(
	(theme, { visible }: { visible: boolean }) => ({
		root: {
			height: `calc(4 * ${theme.spacing["xl"]})`,
		},
	})
)
