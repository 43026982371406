/** 現在のユーザー(currentUser)を管理するatom
 **/
import { gql, useFragment } from "@/scripts/generated"
import type { UserType } from "@/scripts/generated/graphql"
import type { ResultOf } from "@graphql-typed-document-node/core"
import { atom, useAtomValue } from "jotai"
import { gqlClient } from "../networking/graphql-client"
import invariant from "tiny-invariant"

const GetCurrentUserQuery = gql(/* GraphQL */ `
  query GetCurrentUserQuery {
    me {
      user {
        ...CurrentUser
      }
    }
  }
`)

const CurrentUserFragment = gql(/** GraphQL */ `
  fragment CurrentUser on User {
    id
    name
    company_name
    permission

    groups {
      name
      id
      yodobashi_application
    }

    client {
      id
    }
  }
`)

const currentUserQueryAtom2 = atom(async () => {
	const ret = await gqlClient
		.query(
			GetCurrentUserQuery,
			{},
			{
				requestPolicy: "cache-first",
			}
		)
		.toPromise()
	// throw されるはずなので、 ret.data は必ず存在する
	invariant(ret.data, "data is required")
	return ret.data
})

const currentUserQueryOrNull = atom(
	async (get): Promise<UseCurrentUserFetchedState> => {
		const { me } = await get(currentUserQueryAtom2)
		const user = useFragment(CurrentUserFragment, me.user)

		return [user, user.permission]
	}
)

type UseCurrentUserFetchedState = [
	user: ResultOf<typeof CurrentUserFragment>,
	permission?: UserType | null,
]

/** 現在のユーザーを返します */
export const useCurrentUser = () => useAtomValue(currentUserQueryOrNull)
