import type React from "react"
import { forwardRef } from "react"
import imgLogo from "@/images/common/logo.svg"
import { SidebarNav, type SidebarNavProps } from "./SidebarNav"
import { Box, createStyles } from "@mantine/core"

export const ApplicationLayout = (props: any) => (
	<Box className={"admin"}>
		<div className="wrapper">{props.children}</div>
	</Box>
)

export const Main = (props: { children: React.ReactNode }) => (
	<div
		id="mainPanel"
		className="mainPanel wrapper__item wrapper__item--mainPanel"
	>
		{props.children}
	</div>
)

export const Header = (props: { children: React.ReactNode }) => {
	const { classes, cx } = useAppShellStyle()
	return (
		<header
			id="mainHeader"
			className={cx("mainHeader wrapper__itemHead", classes.header)}
		>
			{props.children}
		</header>
	)
}

export const Content = forwardRef<
	HTMLDivElement,
	{ children: React.ReactNode }
>((props, ref) => (
	<div id="mainContent" className="mainContent" ref={ref}>
		{props.children}
	</div>
))

export const ContentSection = forwardRef<
	HTMLDivElement,
	{ children: React.ReactNode }
>((props, ref) => (
	<section ref={ref} className="contentSection">
		{props.children}
	</section>
))

export const Footer = (props: { children: React.ReactNode }) => (
	<footer id="mainFooter" className="mainFooter">
		{props.children}
	</footer>
)

export const Sidebar = (props: SidebarNavProps) => {
	const { classes, cx } = useAppShellStyle()
	return (
		<aside
			id="mainSidebar"
			className={cx(
				"mainSidebar wrapper__item wrapper__item--mainSidebar",
				classes.sidebar
			)}
		>
			<a href="/" className="mainSidebar__logoLink wrapper__itemHead">
				<h1 className="mainSidebar__logo">
					<img className="mainSidebar__logoImage" src={imgLogo} alt="Tomas" />
				</h1>
			</a>
			<SidebarNav {...props} />
		</aside>
	)
}

export const DetailFooterWrap = () => <div />

export const useAppShellStyle = createStyles(() => {
	return {
		header: {
			zIndex: 100,
		},
		sidebar: {
			zIndex: 100,
			display: "flex",
			flexDirection: "column",
		},
	}
})
