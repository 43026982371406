import ImgCancel from "@/images/icon/delete.svg?react"
import ImgCameraIcon from "@/images/icon/icon--camera.svg?react"
import { useFileObjectURL } from "@/scripts/contexts/react/hooks/useFileObjectURL"
import {
	ActionIcon,
	AspectRatio,
	BackgroundImage,
	Box,
	Center,
	Input,
	type InputWrapperProps,
	Stack,
	Text,
} from "@mantine/core"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"
import type React from "react"
import { forwardRef, useState } from "react"

type FileInputWithPreviewProps = {
	name?: string
	onChangeFile?: (file: File | undefined) => unknown
	errors?: React.ReactNode
	wrapperProps?: Omit<Partial<InputWrapperProps>, "errors">
	accept?: JSX.IntrinsicElements["input"]["accept"]
}

/** 画像を選択してその場でプレビューするInput */
export const FileInputWithPreview = forwardRef<
	HTMLInputElement,
	FileInputWithPreviewProps
>(
	(
		{
			onChangeFile,
			errors,
			wrapperProps,
			accept,
			...other
		}: FileInputWithPreviewProps,
		ref
	) => {
		const [file, setFile] = useState<File | undefined>()
		const filePreview = useFileObjectURL(file)
		const handleSelect = useCallbackRef(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				const file = e.target.files?.[0]

				onChangeFile?.(file)

				if (!file) {
					return
				}
				setFile(file)
			}
		)

		const handleUnselect = useCallbackRef(() => {
			onChangeFile?.(undefined)
			setFile(undefined)
		})

		return (
			<Input.Wrapper error={errors} {...wrapperProps}>
				<AspectRatio
					ratio={1}
					sx={(theme) => ({
						position: "relative",
						backgroundColor: theme.colors.gray[6],
						color: theme.white,
					})}
				>
					<Center sx={{ flexDirection: "column" }}>
						<Stack align={"center"} sx={{ zIndex: 0 }}>
							<ImgCameraIcon />
							<Text size={"sm"}>カバー画像を変更</Text>
						</Stack>
					</Center>

					{filePreview && (
						<Box
							sx={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								cursor: "pointer",
							}}
						>
							<ActionIcon
								onClick={handleUnselect}
								variant={"transparent"}
								sx={{
									position: "absolute",
									top: "4px",
									right: "4px",
									backgroundColor: "transparent",
									zIndex: 3,
								}}
								radius={"xl"}
							>
								<ImgCancel
									style={{ backgroundColor: "white", borderRadius: "50%" }}
								/>
							</ActionIcon>
							<BackgroundImage
								sx={{
									width: "100%",
									height: "100%",
									backgroundPosition: "center",
									backgroundSize: "contain",
									backgroundRepeat: "no-repeat",
									zIndex: 1,
								}}
								src={filePreview}
							/>
						</Box>
					)}

					<Box
						component={"input"}
						ref={ref}
						type={"file"}
						multiple={false}
						accept={accept ?? "image/*"}
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							opacity: 0,
							cursor: "pointer",
							zIndex: 2,
						}}
						{...other}
						onChange={handleSelect}
					/>
				</AspectRatio>
			</Input.Wrapper>
		)
	}
)
