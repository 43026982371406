import { PageTitle } from "@/scripts/components/common-ui/PageTitle"
import {
	LayoutContent,
	LayoutFooter,
} from "@/scripts/contexts/client/components/Layout"

export const ClientIndexPage = () => (
	<>
		<PageTitle title="ダッシュボード" />
		<LayoutContent>content for client</LayoutContent>
		<LayoutFooter>footer</LayoutFooter>
	</>
)
