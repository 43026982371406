import { type FragmentType, gql, useFragment } from "@/scripts/generated"

/** データボックス情報 */
export const DataboxHeaderInfo = (props: {
	file?: FragmentType<typeof DataboxHeaderInfoFragment> | null
}) => {
	const file = useFragment(DataboxHeaderInfoFragment, props.file)
	return (
		<section className="dataBox__info">
			<h2 className="dataBox__infoCaption">フォルダー / ファイル 数</h2>
			<ul className="dataBox__infoList">
				<li className="dataBox__infoListItem">フォルダ: {file?.num_folders}</li>
				<li className="dataBox__infoListItem">ファイル: {file?.num_files}</li>
			</ul>
		</section>
	)
}

const DataboxHeaderInfoFragment = gql(/* GraphQL */ `
  fragment DataboxHeaderInfo on DataboxView {
    num_folders
    num_files
  }
`)
