import { eventmit, type Eventmitter } from "eventmit"

export type JobFunc<T> = (
	onProgress: (event: { loaded: number; total: number }) => void
) => Promise<T>
export type ProgressEvent = {
	progress: number
	allProgress: number
	idx: number
	of: number
}

// 直列キュー
export class SequentialTaskQueue<T> {
	private readonly emitter: Eventmitter<ProgressEvent>
	private current: Array<JobFunc<T>>
	private readonly queues: Array<JobFunc<T>>

	constructor(queue: Array<JobFunc<T>>) {
		this.queues = [...queue].reverse()
		this.current = [...this.queues]
		this.emitter = eventmit()
	}

	public onProgress(cb: (event: ProgressEvent) => void) {
		this.emitter.on(cb)
	}

	private async updateProgress(
		meta: { all: number; idx: number },
		event: { loaded: number; total: number }
	) {
		const progress = event.loaded / event.total
		this.emitter.emit({
			progress: progress * 100,
			allProgress:
				100 * (progress / meta.all + (meta.idx > 0 ? meta.idx / meta.all : 0)),
			idx: meta.idx,
			of: meta.all,
		})
	}

	public async dequeue(
		onProgress: (event: { loaded: number; total: number }) => void
	) {
		const queue = this.current.pop()
		if (!queue) return false
		const result = await queue(onProgress)
		return { result }
	}

	public async dequeueAll() {
		const results: Array<T> = []
		let idx = 0
		const all = this.queues.length
		while (true) {
			const r = await this.dequeue(this.updateProgress.bind(this, { all, idx }))
			if (!r) break
			results.push(r.result)
			idx++
		}
		this.emitter.emit({
			progress: 100,
			allProgress: 100,
			idx: all - 1,
			of: all,
		})
		return results
	}
}
