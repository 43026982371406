import type React from "react"
import type { DataboxDisplayMode } from "./DataboxDisplayMode"
import { Group, Text, UnstyledButton } from "@mantine/core"
import ImgGrid from "../../../../images/icon/icon--column.svg?react"
import ImgList from "../../../../images/icon/icon--list.svg?react"

export const DisplayStyleToggleButton = (props: {
	mode: "grid" | "list"
	onToggle: (
		value?: React.SetStateAction<DataboxDisplayMode> | undefined
	) => void
}) => {
	return (
		<UnstyledButton
			sx={(theme) => ({
				cursor: "pointer",
				fontSize: theme.fontSizes.md,
				color: theme.colors.blueGray[8],
			})}
			onClick={() => props.onToggle()}
		>
			<Group spacing={"xs"}>
				{props.mode === "list" ? <ImgGrid fontSize={"24px"} /> : <ImgList />}
				<Text size={"md"}>
					{props.mode === "list" && "カラム表示"}
					{props.mode === "grid" && "リスト表示"}
				</Text>
			</Group>
		</UnstyledButton>
	)
}
