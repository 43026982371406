import { DATABOX_MIME_TYPE } from "@/scripts/contexts/_common/lib/fileTypes"
import {
	type UploaderQueueRef,
	useFileUploader,
} from "@/scripts/contexts/databox/components/useFileUploader"
import { useHandlerRef } from "@/scripts/contexts/react/hooks/useHandlerRef"
import { getFileUUID } from "@/scripts/contexts/upload/getFileUUID"
import { gql } from "@/scripts/generated"
import type { CreateFileMutationVariables } from "@/scripts/generated/graphql"
import { Button, Group, Stack, createStyles } from "@mantine/core"
import { useModals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { useCallback, useRef } from "react"
import { isTruthy } from "typesafe-utils"
import { useMutation } from "urql"
import { DNDFileUpload } from "../../upload/components/DNDFileUpload"
import { FileUploadProgress } from "../../upload/components/FileUploadProgress"

export const useFileCreateModal = () => {
	const modal = useModals()
	return useCallback(
		(props: Pick<FileCreateModalContentProps, "params">) => {
			const modalID = modal.openModal({
				title: "ファイルを作成",
				size: "xl",
				children: (
					<FileCreateModalContent
						onClose={() => modal.closeModal(modalID)}
						params={props.params}
					/>
				),
				closeOnEscape: false,
				closeOnClickOutside: false,
			})
		},
		[modal]
	)
}
type FileCreateModalContentProps = {
	onClose: () => unknown
	params: Omit<CreateFileMutationVariables, "storage_path">
}

const FileCreateModalContent = (props: FileCreateModalContentProps) => {
	const queue: UploaderQueueRef = useRef()
	const { setFiles, progress, files } = useFileUploader(queue)
	const [createFileResult, createFile] = useMutation(CreateFileMutationDoc)

	const handleSubmit = useCallback<
		Required<JSX.IntrinsicElements["form"]>["onSubmit"]
	>(
		async (e) => {
			e.preventDefault()
			const paths = Array.from(queue.current!.values())
				.filter((p) => files.includes(p.file))
				.map((v) => {
					const state = v.status
					if (state.type === "complete") {
						return state.result.storage_path
					}
				})
				.filter(isTruthy)

			const result = await createFile(
				{
					storage_path: paths,
					...props.params,
				},
				{
					additionalTypenames: ["Databox", "DataboxView"],
					suspense: false,
				}
			)

			if (!result.error) {
				props.onClose()
				showNotification({
					title: "処理完了",
					message: "ファイルを作成しました",
				})
			}
		},
		[queue, files, props.params]
	)
	const [wrapHandler] = useHandlerRef()

	const { classes } = useStyles()
	console.log(files)

	return (
		<form onSubmit={handleSubmit}>
			<Stack>
				<DNDFileUpload
					onDrop={(files) => {
						console.log(files)
						return setFiles((current) => [...current, ...files])
					}}
					onReject={(files) => {
						console.log(files)
					}}
					accept={DATABOX_MIME_TYPE}
				/>
				<Stack mt="xl" mb={"md"}>
					{files.map((file, idx) => (
						<Group key={idx} className={classes.progress}>
							<FileUploadProgress
								file={file}
								progress={
									progress[getFileUUID(file)] ?? {
										state: "progress",
										progress: 0,
									}
								}
								classNames={{
									root: classes.progress_bar,
								}}
							/>
							<Button
								size={"xs"}
								variant={"filled"}
								color={"red"}
								onClick={wrapHandler(() => {
									setFiles((current) => current.filter((v) => v !== file))
								}, file)}
							>
								削除
							</Button>
						</Group>
					))}
				</Stack>
				{
					// TODO: エラーメッセージ取得
					createFileResult.error && <div>ファイルを作成できませんでした。</div>
				}
				<Group mx={"auto"}>
					<Button type="button" variant="white" onClick={props.onClose}>
						キャンセル
					</Button>
					<Button
						type="submit"
						disabled={files.length === 0 || createFileResult.fetching}
					>
						アップロード
					</Button>
				</Group>
			</Stack>
		</form>
	)
}

const useStyles = createStyles((_theme) => ({
	progress: {
		width: "100%",
	},
	progress_bar: {
		flexGrow: 1,
	},
}))

const CreateFileMutationDoc = gql(/* GraphQL */ `
  mutation CreateFile($storage_path: [String!]!, $databoxViewId: ID!) {
    databoxFileCreate(storage_path: $storage_path, databoxViewId: $databoxViewId) {
      __typename
      id

      parentView {
        __typename
        id
      }
    }
  }
`)
