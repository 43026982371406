import {
	Box,
	type DefaultProps,
	type MantineNumberSize,
	type MantineSize,
	createStyles,
} from "@mantine/core"
import type React from "react"
import { forwardRef } from "react"

interface SizedIconProps
	extends DefaultProps,
		React.ComponentPropsWithRef<"div"> {
	size: MantineNumberSize
	children: React.ReactNode
}

export const SizedIcon = forwardRef<HTMLDivElement, SizedIconProps>(
	({ size, children, className, ...others }, ref) => {
		const { classes, cx } = createIconStyles({ size })
		return (
			<Box className={cx(classes.root, className)} ref={ref} {...others}>
				{children}
			</Box>
		)
	}
)

type SizeIconStylesParams = {
	size: MantineNumberSize
}

const sizes: Record<MantineSize, number> = {
	xs: 16,
	sm: 20,
	md: 26,
	lg: 32,
	xl: 40,
}

const createIconStyles = createStyles(
	(theme, { size }: SizeIconStylesParams) => {
		const iconSize = typeof size === "number" ? size : sizes[size]

		return {
			root: {
				...theme.fn.fontStyles(),
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				boxSizing: "border-box",
				width: iconSize,
				height: iconSize,
				minWidth: iconSize,
				minHeight: iconSize,
				"& > svg": {
					width: iconSize,
					height: iconSize,
				},
			},
		}
	}
)
