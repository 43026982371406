import { gql } from "@/scripts/generated"
import type { Client, Databox, PageInfo } from "@/scripts/generated/graphql"
import { isNode } from "@/scripts/lib/graphql-consumer"
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
import { Text, UnstyledButton } from "@mantine/core"
import { useCallbackRef } from "@radix-ui/react-use-callback-ref"
import clsx from "clsx"
import type React from "react"
import {
	type ComponentPropsWithoutRef,
	useCallback,
	useMemo,
	useState,
} from "react"
import type { SetRequired } from "type-fest"
import { useQuery } from "urql"
import type { DataboxDisplayMode } from "./DataboxDisplayMode"
import { InfiniteLoader } from "./InfiniteLoader"

/** データボックスのアイテム一覧 */
export const DataboxItemListWithPagination = <T extends DocumentNode<any, any>>(
	props: DataboxItemWithPaginationProps<T, DataboxItem>
) => {
	const [pageVariables, setPageVariables] = useState<
		Array<typeof props.variables>
	>([props.variables])
	const handleOnLoadNext = useCallback<
		Required<DataboxItemListWithQueryInnerProps>["onLoadNext"]
	>((after) => {
		setPageVariables((current) => [...current, { ...props.variables, after }])
	}, [])
	return (
		<>
			{pageVariables.map((page, i) => (
				<DataboxItemListWithQuery
					useQuery={props.useQuery}
					key={"" + page.after}
					mode={props.mode}
					variables={page}
					selectedNodeIds={props.selectedNodeIds}
					getListItem={props.getListItem}
					onClickItem={props.onClickItem}
					isLastPage={i === pageVariables.length - 1}
					onLoadNext={handleOnLoadNext}
				/>
			))}
		</>
	)
}

const DataboxItemListWithQuery = (
	props: DataboxItemListWithQueryInnerProps
) => {
	const ret = useQuery({
		query: props.useQuery,
		variables: props.variables,
		context: useMemo(() => ({ suspense: false }), []),
	})

	const [{ data, error, fetching }] = ret
	const items = props.getListItem(data)
	return (
		<>
			{error && <Text>読み込みできませんでした</Text>}
			{items && (
				<>
					<DataboxItemList
						mode={props.mode}
						data={items.nodes}
						onClickItem={props.onClickItem}
						selectedItemIds={props.selectedNodeIds}
					/>
					{props.isLastPage && items.pageInfo.hasNextPage && (
						<InfiniteLoader
							loading={fetching}
							onLoadNext={() => {
								props.onLoadNext?.(items.pageInfo.endCursor!)
							}}
						/>
					)}
				</>
			)}
		</>
	)
}

type DataboxItemListWithQueryInnerProps = DataboxItemWithPaginationProps<
	DocumentNode<any, any>,
	DataboxItem
> & {
	isLastPage?: boolean
	onLoadNext?: (nextCursor: string) => void
}

type QueryReturnType<T extends DocumentNode> = T extends DocumentNode<
	infer R,
	any
>
	? R
	: never

type QueryVariableType<T extends DocumentNode> = T extends DocumentNode<
	any,
	infer V
>
	? V
	: never

export type DataboxItemWithPaginationProps<
	Q extends DocumentNode<any, any>,
	NodeType extends DataboxItem = DataboxItem,
> = {
	useQuery: Q
	getListItem: (result?: QueryReturnType<Q>) =>
		| {
				nodes: NodeType[]
				pageInfo: SetRequired<Partial<PageInfo>, "hasNextPage">
		  }
		| undefined
	/** 選択中のItemのID */
	selectedNodeIds?: NodeType["id"][]
	variables: QueryVariableType<Q>
} & Pick<DataboxItemListProps<NodeType>, "onClickItem" | "mode">

export type DataboxItemListProps<T extends DataboxItem> = {
	mode: DataboxDisplayMode
	data: T[]
	selectedItemIds?: T["id"][]
	onClickItem?: (item: T, e: React.MouseEvent<HTMLButtonElement>) => unknown
}

export type DataboxItem =
	| Pick<Client, "id" | "name" | "__typename">
	| Pick<
			Databox,
			| "id"
			| "name"
			| "__typename"
			| "fileType"
			| "thumbnailImageUrl"
			| "trashed"
			| "nodeType"
	  >

/** クライアントの一覧  */
export const DataboxItemList = <T extends DataboxItem>(
	props: DataboxItemListProps<T>
) => {
	const onClickItem = useCallbackRef(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			const id = e.currentTarget.dataset["index"]
			const item = props.data[Number(id)]
			item && props.onClickItem?.(item, e)
		}
	)
	return (
		<ul
			className={clsx(
				"columnBox columnBox--5",
				{
					list: "columnBox--isList",
					grid: null,
				}[props.mode]
			)}
		>
			{props.data.map((item, idx) => (
				<DataboxListItem
					key={item.id}
					selected={props.selectedItemIds?.includes(item.id) ?? false}
					onClick={onClickItem}
					data-index={idx.toString()}
					item={item}
				/>
			))}
		</ul>
	)
}

const DataboxClientListFragment = gql(/* GraphQL */ `
  fragment DataboxClientList on Client {
    id
    dbId
    name
    __typename
  }
`)

const DataboxFileListFragment = gql(/* GraphQL */ `
  fragment DataboxFileList on Databox {
    id
    name
    thumbnailImageUrl
    trashed
    fileType
    __typename
  }
`)

/** リスト項目 */
const DataboxListItem = ({
	item,
	onClick,
	selected,
	...props
}: {
	item: DataboxItem
	"data-index": string
	selected?: boolean
} & Pick<ComponentPropsWithoutRef<"button">, "onClick">) => {
	const databox = isNode(item, "Databox") ? item : null
	const extension = databox ? databox.fileType : "folder"
	const iconStyle =
		databox && databox.thumbnailImageUrl
			? { backgroundImage: `url("${databox.thumbnailImageUrl}")` }
			: undefined
	const trashed = databox && databox.trashed

	return (
		<li className="columnBox__item">
			<UnstyledButton
				onClick={onClick}
				sx={(theme) => ({
					fontSize: theme.fontSizes.sm,
					width: "100%",
				})}
				data-index={props["data-index"]}
				data-extension={extension}
				className={clsx(
					"dataBoxListLink",
					selected && "dataBoxListLink--isSelected",
					trashed && "dataBoxListLink--isDeletFlag"
				)}
			>
				<div className="dataBoxListLink__icon">
					<i
						className="icon icon--folderLarge"
						data-extension={extension}
						style={iconStyle}
					/>
				</div>
				<p className="dataBoxListLink__caption">{item.name}</p>
			</UnstyledButton>
		</li>
	)
}
