import { AdminIndexPage } from "@/scripts/contexts/admin/pages"
import {
	type DefaultGenerics,
	Outlet,
	type Route,
} from "@tanstack/react-location"
import { StoreYbIndexPage } from "./contexts/store-yb/pages"
import { ClientIndexPage } from "./contexts/client/pages"
import {
	RedirectToClientIDSubPath,
	RedirectToRoleSubPath,
} from "@/scripts/contexts/auth/components/RedirectToDashboard"
import { Layout as AdminLayout } from "@/scripts/contexts/admin/components/Layout"
import { Layout as ClientLayout } from "@/scripts/contexts/client/components/Layout"
import { Layout as StoreYbLayout } from "@/scripts/contexts/store-yb/components/Layout"

const databoxRoutes: TRoute[] = [
	{
		path: "file",
		children: [
			{
				path: ":file_id",
				element: () =>
					import("./pages/databox/show").then((m) => <m.DataboxShowPage />),
			},
		],
	},
	{
		path: "/:client_id",
		children: [
			{
				path: "/",
				element: () =>
					import("./pages/databox/index").then((m) => <m.DataboxIndexPage />),
			},
			{
				path: "/:folder_id",
				element: () =>
					import("./pages/databox/index").then((m) => <m.DataboxIndexPage />),
			},
		],
	},
]

const databoxUserRoutes: TRoute[] = [
	{
		path: "/",
		element: <RedirectToClientIDSubPath />,
	},
	...databoxRoutes,
]

const databoxAdminRoutes: TRoute[] = [
	{
		path: "/",
		element: () =>
			import("./pages/databox/client-index").then((m) => (
				<m.DataboxClientIndexPage />
			)),
	},
	...databoxRoutes,
]

// Routes by role

const adminRoutes: TRoute[] = [
	{
		path: "/",
		element: <AdminIndexPage />,
	},
	{
		path: "databox",
		children: databoxAdminRoutes,
	},
	{
		path: "material",
		children: [
			{
				path: "/",
				element: () =>
					import("./pages/material/index").then((m) => <m.MaterialIndexPage />),
			},
		],
	},
	// {
	//   path: "material",
	//   children: [
	//     { path: ":item_id", element: () => import("./pages/material/show").then((m) => <m.MaterialShowPage />) },
	//   ],
	// },
]

const clientRoutes: TRoute[] = [
	{
		path: "/",
		element: <ClientIndexPage />,
	},
	{
		path: "databox",
		children: databoxUserRoutes,
	},
	{
		path: "material",
		children: [
			{
				path: "add",
				element: () =>
					import("./pages/material/add").then((m) => <m.MaterialAddPage />),
			},
			// { path: ":item_id", element: () => import("./pages/material/show").then((m) => <m.MaterialShowPage />) },
		],
	},
]

const ybRoutes: TRoute[] = [
	{
		path: "/",
		element: <StoreYbIndexPage />,
	},
	{
		path: "databox",
		children: databoxUserRoutes,
	},
]

export type TRoute = Route<DefaultGenerics>

export const routes: TRoute[] = [
	// {
	//   path: "/",
	//   element: <RedirectToDashboard />,
	// },
	{
		path: "admin",
		children: adminRoutes,
		element: (
			<AdminLayout>
				<Outlet />
			</AdminLayout>
		),
	},
	{
		path: "client",
		children: clientRoutes,
		element: (
			<ClientLayout>
				<Outlet />
			</ClientLayout>
		),
	},
	{
		path: "yodobashi",
		children: ybRoutes,
		element: (
			<StoreYbLayout>
				<Outlet />
			</StoreYbLayout>
		),
	},
	{
		path: "databox",
		element: <RedirectToRoleSubPath subpath={"databox"} />,
	},
]

export type RouteMetadata = {
	role?: string
}
