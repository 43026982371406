import { FileInputWithPreview } from "@/scripts/contexts/databox/components/handleSelect"
import { useFormSelectFile } from "@/scripts/contexts/databox/components/useFormSelectFile"
import { validateFolderFile } from "@/scripts/contexts/databox/folderValidation"
import { gql } from "@/scripts/generated"
import type { CreateFolderMutationVariables } from "@/scripts/generated/graphql"
import { Button, Grid, Group, Stack, TextInput, Title } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useModals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { type FormEvent, useCallback, useRef } from "react"
import { useMutation } from "urql"
import { FOLDER_THUMBNAIL_MIME_TYPE } from "../../_common/lib/fileTypes"

export const useFolderCreateModal = () => {
	const modal = useModals()
	return useCallback(
		(props: Pick<FolderCreateModalContentProps, "params">) => {
			const modalID = modal.openModal({
				title: <Title order={3}>フォルダを作成</Title>,
				size: "xl",
				children: (
					<FolderCreateModalContent
						onClose={() => modal.closeModal(modalID)}
						params={props.params}
					/>
				),
			})
		},
		[modal]
	)
}

type FolderCreateModalContentProps = {
	onClose: () => unknown
	params: Omit<CreateFolderMutationVariables, "thumbnail" | "name">
}

const FolderCreateModalContent = (props: FolderCreateModalContentProps) => {
	const form = useForm({
		initialValues: {
			name: "",
			["thumbnail"]: "",
		},
		validate: {
			name: (value) => {
				return value.length > 255 ? "255文字以内で入力してください" : null
			},
		},
	})
	const handleChangeFile = useFormSelectFile(
		form,
		"thumbnail",
		validateFolderFile
	)

	const [createFolderResult, createFolder] = useMutation(
		CreateFolderMutationDoc
	)
	const handleSubmit = async (values: typeof form.values, event: FormEvent) => {
		event.preventDefault()

		const result = await createFolder(
			{
				...props.params,
				name: values.name,
				thumbnail: fileInputRef.current?.files?.[0],
			},
			{
				additionalTypenames: ["Databox", "DataboxView"],
			}
		)

		if (!result.error) {
			props.onClose()
			showNotification({
				title: "処理完了",
				message: "フォルダを作成しました",
			})
			return
		}
	}

	const fileInputRef = useRef<HTMLInputElement>(null)

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Stack>
				<Grid grow>
					<Grid.Col span={2}>
						<FileInputWithPreview
							ref={fileInputRef}
							name={"thumbnail"}
							onChangeFile={handleChangeFile}
							errors={form.errors["thumbnail"]}
							accept={FOLDER_THUMBNAIL_MIME_TYPE.join(",")}
						/>
					</Grid.Col>

					<Grid.Col span={8}>
						<TextInput
							size={"md"}
							label={"フォルダ名"}
							placeholder={"新しいフォルダ"}
							autoComplete="off"
							data-autofocus
							maxLength={255}
							required
							{...form.getInputProps("name")}
						/>
					</Grid.Col>
				</Grid>

				{
					// TODO: エラーメッセージ取得
					createFolderResult.error && (
						<div>フォルダを作成できませんでした。</div>
					)
				}

				<Group mx={"auto"}>
					<Button type="button" variant="white" onClick={props.onClose}>
						キャンセル
					</Button>
					<Button type="submit" disabled={createFolderResult.fetching}>
						この内容で作成
					</Button>
				</Group>
			</Stack>
		</form>
	)
}

const CreateFolderMutationDoc = gql(/* GraphQL */ `
  mutation CreateFolder($name: String!, $databoxViewId: ID!, $thumbnail: Upload) {
    databoxFolderCreate(name: $name, databoxViewId: $databoxViewId, thumbnail: $thumbnail) {
      __typename
      id

      parentView {
        __typename
        id
      }
    }
  }
`)
