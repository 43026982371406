import { useCurrentUser } from "@/scripts/contexts/auth/context"
import { Navigate, useMatches } from "@tanstack/react-location"
import { LoadingOverlay } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { UserType } from "@/scripts/generated/graphql"

export const RedirectToDashboard = () => {
	const [_] = useCurrentUser()
	return <RedirectToRoleDashboard />
}

export const RedirectToRoleSubPath = (props: { subpath: string }) => {
	const [user] = useCurrentUser()

	const ns = user.permission ? NS_PER_USER_TYPE[user.permission] : null
	return <Navigate replace to={`/${ns}/${props.subpath}`} />
}

/** 現在のパスが違うUser.permission用の画面であればリダイレクトします */
export const RedirectIfOtherRole = () => {
	const matches = useMatches()
	const [user] = useCurrentUser()

	const ns = matches.length >= 2 ? matches[1] : null
	if (!ns) return null

	const expected_ns = user.permission ? NS_PER_USER_TYPE[user.permission] : null

	if (ns.id !== expected_ns) {
		return <RedirectToRoleDashboard />
	}

	return null
}

/** 現在のクライアントIDが入ったパスにリダイレクトします。
 *
 *  基本は相対パスで今のURLの1階層下(foo/bar -> foo/bar/:client_id)
 */
export const RedirectToClientIDSubPath = ({
	basePath = "./",
}: { basePath?: string }) => {
	const [user] = useCurrentUser()

	if (!user.client?.id) {
		return <LoadingOverlay visible />
	}

	return <Navigate replace to={`${basePath}${user.client.id}`} />
}

const NS_PER_USER_TYPE: Record<UserType, string> = {
	[UserType.Admin]: "admin",
	[UserType.Client]: "client",
	[UserType.StoreYb]: "yodobashi",
}

const RedirectToRoleDashboard = () => {
	const [user] = useCurrentUser()

	switch (user.permission) {
		case UserType.Admin:
			return <Navigate replace to={"/admin"} />
		case UserType.Client:
			return <Navigate replace to={"/client"} />
		case UserType.StoreYb:
			return <Navigate replace to={"./yodobashi"} />
	}
	return null
}

const MetaRedirect = (props: { after?: number; destination: string }) => {
	return (
		<Helmet>
			<meta
				httpEquiv="refresh"
				content={`${props.after ?? 0}; url=${props.destination}`}
			/>
		</Helmet>
	)
}
