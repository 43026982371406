export const MIME_TYPES = {
	bmp: "image/bmp",
	tif: "image/tif",
	tiff: "image/tiff",
	tga: "image/tga",
	png: "image/png",
	gif: "image/gif",
	jpeg: "image/jpeg",
	jpg: "image/jpg",
	svg: "image/svg+xml",
	webp: "image/webp",
	mp4: "video/mp4",
	zip: "application/zip",
	zip_win: "application/x-zip-compressed",
	csv: "text/csv",
	pdf: "application/pdf",
	psd: "image/vnd.adobe.photoshop",
	ai: "application/illustrator",
	eps: "application/postscript",
	rar: "application/x-rar-compressed",
	sit: "application/x-stuffit",
	tar: "application/x-tar",
	cab: "application/x-cab",
	lzh: "application/x-lzh",
	doc: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	xls: "application/vnd.ms-excel",
	xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	ppt: "application/vnd.ms-powerpoint",
	pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	exe: "application/vnd.microsoft.portable-executable",
} as const

// TODO: サーバサイドとの一貫性をチェック

const PHP_UPLOAD_FILE_MIMES_IMAGE01 = [
	MIME_TYPES.png,
	MIME_TYPES.jpg,
	MIME_TYPES.jpeg,
	MIME_TYPES.gif,
]
const PHP_UPLOAD_FILE_MIMES_IMAGE02 = [
	MIME_TYPES.bmp,
	MIME_TYPES.tif,
	MIME_TYPES.pdf,
	MIME_TYPES.psd,
	MIME_TYPES.ai,
	MIME_TYPES.eps,
	MIME_TYPES.tga,
]
const PHP_UPLOAD_FILE_MIMES_ARCHIVE = [
	MIME_TYPES.zip,
	MIME_TYPES.zip_win,
	MIME_TYPES.rar,
	MIME_TYPES.sit,
	MIME_TYPES.tar,
	MIME_TYPES.cab,
	MIME_TYPES.lzh,
]
const PHP_UPLOAD_FILE_MIMES_OFFICE = [
	MIME_TYPES.doc,
	MIME_TYPES.docx,
	MIME_TYPES.xls,
	MIME_TYPES.xlsx,
	MIME_TYPES.ppt,
	MIME_TYPES.pptx,
]

/** データボックスのフォルダ画像としてアップロード可能なMIME TYPE */
export const FOLDER_THUMBNAIL_MIME_TYPE = [...PHP_UPLOAD_FILE_MIMES_IMAGE01]

/** データボックスのファイルとしてアップロード可能なMIME TYPE */
export const DATABOX_MIME_TYPE = [
	...PHP_UPLOAD_FILE_MIMES_IMAGE01,
	...PHP_UPLOAD_FILE_MIMES_IMAGE02,
	...PHP_UPLOAD_FILE_MIMES_OFFICE,
	...PHP_UPLOAD_FILE_MIMES_ARCHIVE,
]

/** 販促物としてアップロード可能なファイルのMIME TYPE */
export const MATERIALS_MIME_TYPE = [
	...PHP_UPLOAD_FILE_MIMES_IMAGE01,
	...PHP_UPLOAD_FILE_MIMES_IMAGE02,
	...PHP_UPLOAD_FILE_MIMES_ARCHIVE,
	...PHP_UPLOAD_FILE_MIMES_OFFICE,
]
